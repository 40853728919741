import React from "react";
import classes from "./NavBar.module.css";
import { NavLink } from "react-router-dom";
import logo from "../../asset/dashboard_logo.svg";
import { useDispatch } from "react-redux";
import { logout } from "../Redux/Slices/auth";

import {
  viewUserRoute,
  auditLogRoute,
  addTerminalRoute,
  viewKycRoute,
  viewProvidersRoute,
  viewAdminRoute,
  viewNotificationsRoute,
} from "../Lib/routing";

const NavBar = () => {
  const dispatch = useDispatch();

  const roles = JSON.parse(localStorage.getItem("ROLES"));
  const addTerminal = addTerminalRoute(roles);
  const viewUser = viewUserRoute(roles);
  const auditLog = auditLogRoute(roles);
  const viewAdmin = viewAdminRoute(roles);
  const viewKyc = viewKycRoute(roles);
  const viewProviders = viewProvidersRoute(roles);
  const viewNotifications = viewNotificationsRoute(roles);

  const logoutHandler = () => {
    dispatch(logout());
  };
  return (
    <div className={classes.navContainer}>
      <div className={classes.logo}>
        <img src={logo} alt="Airvend Logo" className={classes.dashboardLogo} />
      </div>

      <div className={classes.linksContainer}>
        <NavLink activeClassName={classes.active} exact to="/">
          Overview
        </NavLink>

        {viewKyc && (
          <NavLink activeClassName={classes.active} to="/kyc">
            KYC <br /> Submissions
          </NavLink>
        )}
        {viewUser && (
          <NavLink activeClassName={classes.active} to="/users">
            User <br /> Management
          </NavLink>
        )}
        {viewAdmin && (
          <NavLink activeClassName={classes.active} to="/admin-management">
            Admin <br /> Management
          </NavLink>
        )}
        {viewNotifications && (
          <NavLink activeClassName={classes.active} to="/notifications">
            Push <br />
            Notifications
          </NavLink>
        )}
        {viewProviders && (
          <NavLink activeClassName={classes.active} to="/service-providers">
            Service <br /> Providers
          </NavLink>
        )}
        {auditLog && (
          <NavLink activeClassName={classes.active} to="/audit-logs">
            Audit <br /> Logs
          </NavLink>
        )}
        {addTerminal && (
          <NavLink activeClassName={classes.active} to="/terminals">
            Terminals
          </NavLink>
        )}
      </div>
      <div className={classes.buttonContainer}>
        <button className={classes.button} onClick={logoutHandler}>
          Logout
        </button>
      </div>
    </div>
  );
};

export default NavBar;

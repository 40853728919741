import React, { useState } from "react";
import Filter from "../Filter/Filter";
import { Table, Space } from "antd";
import { useParams } from "react-router-dom";
import classes from "./audit.module.css";
import { getTransaction } from "../../Redux/Api/transaction";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../Modal/Modal";
import TransDetails from "./transDetails.jsx";
import moment from "moment";

function UserTransactions({ data }) {
  const { userid } = useParams();
  const dispatch = useDispatch();
  const [type, setType] = useState(data[0].type);
  const [query, setQuery] = useState("");
  const [show, setShow] = useState(false);
  const [userdata, setUserdata] = useState({});
  const [page, setPage] = useState(1);

  const keys = ["amount", "reference"];
  const search = (data) => {
    return data.filter((item) =>
      keys.some((key) => item[key]?.toLowerCase()?.includes(query))
    );
  };

  React.useEffect(() => {
    getTransaction(dispatch, userid, type, page);
  }, [dispatch, userid, type, page]);

  const transData = useSelector((state) => state.transaction.transaction);
  const loading = useSelector((state) => state.transaction.loading);
  const totalPage = useSelector((state) => state.transaction.totalpage);
  const perpage = useSelector((state) => state.transaction.perpage);

  const columns = [
    {
      title: "Timestamp",
      dataIndex: "timestamp",
      key: "timestamp",
      sorter: (a, b) => a.timestamp - b.timestamp,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Charge",
      dataIndex: "charge",
      key: "charge",
    },
    {
      title: "Transaction ID",
      dataIndex: "transaction",
      key: "transaction",
    },
    {
      title: "",
      key: "action",
      render: (key) => (
        <Space size="middle">
          <button
            className={classes.viewd}
            onClick={() => {
              const d = transData?.filter((e) => e.id === key.key)[0];
              setUserdata(d);
              setShow(true);
            }}
          >
            View Details
          </button>
        </Space>
      ),
    },
  ];

  const transactions = transData.map((data) => ({
    key: data.id,
    timestamp: moment(data.created_at).format("LLL"),
    type: type,
    amount: `₦ ${data.amount}`,
    charge: data?.charge || "N/A",
    transaction: data?.transaction_id,
  }));

  return (
    <>
      <div className={classes.selectContainer}>
        <label htmlFor="transaction">Select Bill</label>
        <select
          id="transaction"
          className={classes.selectItem}
          onInput={(e) => {
            setPage(1);
            setType(e.target.value);
          }}
        >
          {data.map((e, i) => (
            <option key={i} value={e.type}>
              {e.type}
            </option>
          ))}
        </select>
      </div>

      <div style={{ margin: "3% 0" }}>
        <Filter data={transactions} />
      </div>
      <Table
        columns={columns}
        dataSource={search(transactions)}
        loading={loading}
        pagination={{
          pageSize: perpage,
          total: totalPage,
          onChange: (page) => {
            setPage(page);
          },
        }}
      />
      {show && (
        <Modal>
          <button onClick={() => setShow(false)} className={classes.closeBtn}>
            Close X
          </button>
          <TransDetails data={userdata} />
        </Modal>
      )}
    </>
  );
}

export default UserTransactions;

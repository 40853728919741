import React, { useState } from 'react'
// import useState from 'react'
import './css/upload-input.css'

interface Props {
  id: string,
  labelName: string,
  appearance?: string,
  type: string,
  size?: 'sm' | 'lg',
  excel?: boolean,
  getFile?: (file: any) => void,
  getExcel?: (event: any) => void
}


export default function UploadInput(props: Props) {
  const { size, excel, getFile, getExcel } = props;
  const [labelState, changeLabelState] = useState(props.labelName);

  const handleChange = (e: any) => {
    const _label = e.target.files[0].name;
    const _file = e.target.files[0];
    if (getFile) {
      getFile(_file);
    }
    if (excel && getExcel) {
      getExcel(e);
    }
    changeLabelState(_label || "");
  }

  const HEIGHT = {
    height: size === 'lg' ? 120 : null,
  }

  return (
    <>
      <label
        className={`upload-selected ${props.appearance ? props.appearance : ""}`}
        style={HEIGHT}
        htmlFor={props.id}>
        {labelState.length > 40 ? `${labelState.slice(0, 40)}...` : (labelState || props.labelName)}
      </label>
      <input id={props.id} style={{ display: "none" }} onChange={handleChange} type={props.type} accept={props.accept || ""} />
    </>
  )
}

import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      displayColors: false,
      yAlign: "bottom",
      callbacks: {
        title: function (context: any) {
          return `Total ${context[0].dataset.label}`;
        },
        label: function (context: any) {
          return `₦ ${context.formattedValue}`;
        },
      },
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        font: {
          family: "Rubik",
        },
      },
    },
    y: {
      grid: {
        display: false,
      },
      ticks: {
        font: {
          family: "Rubik",
        },
        padding: 50,
        callback: function (value: any) {
          function nFormatter(num: any) {
            var si = [
                { value: 1e12, symbol: "T" },
                { value: 1e9, symbol: "B" },
                { value: 1e6, symbol: "M" },
                { value: 1e3, symbol: "k" },
              ],
              i;
            for (i = 0; i < si.length; i++) {
              if (num >= si[i].value) {
                return (
                  (num / si[i].value)
                    .toFixed(1)
                    .replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") + si[i].symbol
                );
              }
            }
            return num.toString();
          }
          return "₦ " + nFormatter(value);
        },
      },
    },
  },
};

const labels = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const LineGraph = ({ data }: any) => {
  const dataInsert = {
    labels,
    datasets: [
      // {
      //   label: "Profit",
      //   data: [1000000, 28000000, 22000000, 4000000, 3200000, 2000000],
      //   borderColor: "#FF0000",
      //   backgroundColor: "rgba(255, 99, 132, 0.5)",
      //   tension: 0.4,
      // },
      {
        label: "Revenue",
        data,
        borderColor: "#2D9E64",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
        tension: 0.4,
      },
    ],
  };
  return (
    <Line options={options} data={dataInsert} style={{ maxHeight: 400 }} />
  );
};

export default LineGraph;

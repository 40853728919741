import React, { useCallback, useState } from "react";
import TextInput from "../../components/text-input/text-input";
import Checkbox from "../../components/checkbox/checkbox";
import Button from "../../components/button/button";
import classes from "./Login.module.css";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginAuth } from "../Redux/Api/login";

function Login() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const loginHandler = useCallback(async () => {
    setLoading(true);

    const payload = {
      login_id: email,
      password: password,
    };
    await loginAuth(payload, dispatch);
    setLoading(false);
    return () => {
      loginAuth();
    };
  }, [email, password, dispatch]);

  return (
    <>
      <div className={`${classes.boxManagement} ${classes.boxBg}`}>
        <div className={classes.logoAreaLogin}>
          <img src={require("../../asset/airvendlogo 1.svg").default} alt="" />
        </div>

        <div className={`${classes.cardCustom} ${classes.boxCard}`}>
          <div className={classes.textArea}>Login</div>
          <br />
          <br />
          <div className={classes.textFormArea}>
            <TextInput
              labelName="Email Address"
              onInput={(e) => setEmail(e.target.value)}
            />
            <br />
            <TextInput
              type="password"
              labelName="Password"
              iconPosition="right-icon"
              onInput={(e) => setPassword(e.target.value)}
              // eslint-disable-next-line jsx-a11y/alt-text
              iconJSX={<img src={require("../../asset/eye.svg").default} />}
            />
          </div>
          <br />
          <div className={classes.checkboxArea}>
            <Checkbox />
          </div>
          <div className="submit-button">
            <Button
              btn_name="Sign in"
              color="btn-green"
              onClick={loginHandler}
              loading={loading}
            />
          </div>
        </div>
        <div className={classes.informationElements}>
          <Link to="/forgot-password">Reset Password</Link>
        </div>
      </div>
    </>
  );
}
export default Login;

import api from "../../Lib/customAxios";
import { errorType, fulfilledType, startType } from "../Slices/transType";

export const transType = async (dispatch) => {
  dispatch(startType());
  try {
    const res = await api.get("/transaction-types");
    if (res.data.code === 200 && res.data.status === "success") {
      dispatch(fulfilledType(res.data.data));
    }
    if (res.data.code === 401) {
      localStorage.removeItem("TOKEN");
      window.location.href = "/";
    } else {
      dispatch(errorType());
    }
  } catch (error) {
    dispatch(errorType());
  }
};

import React from "react";
import Card from "../card/card";
import classes from "../../Admin/admin.module.css";
import { useSelector, useDispatch } from "react-redux";
import { getPosEnvironment, addTerminal } from "../../Redux/Api/terminal";
import CardDetails from "../CardDetails/CardDetails";
import moment from "moment";

function PosTerminals({ account }) {
  const dispatch = useDispatch();
  React.useEffect(() => {
    getPosEnvironment(dispatch);
  }, [dispatch]);

  const data = useSelector((state) => state.terminal.posEnv).map(
    (e) => e.environment_name
  );
  const loading = useSelector((state) => state.terminal.loading);
  const addTerminalloading = useSelector(
    (state) => state.terminal.addTerminalLoading
  );

  const [pos, setPos] = React.useState(data[0]);
  const [terminalid, setTerminalid] = React.useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    addTerminal(dispatch, account.accountId, {
      terminal: terminalid,
      pos_env: pos,
    });
    setTerminalid("");
    setPos(data[0]);
  };
  return (
    <>
      {account.terminal ? (
        <CardDetails
          envname={account.terminal.posenvironment.environment_name}
          merchandID={account.terminal.posenvironment.merchantId}
          rid={account.terminal.posenvironment.rid}
          settlementact={account.terminal.posenvironment.settlementAcct}
          terminalid={account.terminal.terminal.terminal_id}
          lastActive={moment(account.terminal.last_active).fromNow()}
        />
      ) : (
        <form onSubmit={handleSubmit} style={{ marginTop: 30 }}>
          <Card>
            <div>
              <p className={classes.header}>Add Terminal</p>

              <div className={classes.selectContainer}>
                <label htmlFor="pos">Select POS Environment</label>
                <select
                  required
                  id="pos"
                  className={classes.selectItem}
                  onInput={(e) => {
                    setPos(e.target.value);
                  }}
                >
                  {loading ? (
                    <option>Loading Terminal Environment ... </option>
                  ) : (
                    data.map((e, i) => (
                      <option key={i} value={e}>
                        {e}
                      </option>
                    ))
                  )}
                </select>
              </div>
              <div className={classes.container}>
                <input
                  id="terminalId"
                  type="text"
                  value={terminalid}
                  onChange={(e) => {
                    setTerminalid(e.target.value);
                  }}
                  placeholder="Terminal ID"
                  className={classes.containerInput}
                  required
                />

                <label htmlFor="name" className={classes.containerLabel}>
                  Terminal ID
                </label>
              </div>
              <div className={classes.btnContainer}>
                <button type="submit" disabled={addTerminalloading}>
                  {addTerminalloading ? "Adding Terminal" : "Add Terminal"}
                </button>
              </div>
            </div>
          </Card>
        </form>
      )}
    </>
  );
}
export default PosTerminals;

import React from "react";
import Card from "../components/card/card";
import classes from "./admin.module.css";
import { styled } from "@mui/material/styles";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useDispatch, useSelector } from "react-redux";
import { AddPermission, getAdmin, getPermission } from "../Redux/Api/admin";
import Loader from "../components/Loader/Loader";

const Permissions = () => {
  const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    "&.MuiToggleButton-root	": {
      "&:hover": { backgroundColor: "rgba(34, 176, 46, 0.1)" },
    },
    "& .MuiToggleButtonGroup-grouped": {
      margin: theme.spacing(0.5),
      border: 0,
      "&.Mui-disabled": {
        border: 0,
      },
      "&.Mui-selected	": {
        backgroundColor: "rgba(34, 176, 46, 0.1)",
        color: "#22B02E",
        border: "0.7px dashed #22B02E",
      },
      "&:not(:first-of-type)": {
        borderRadius: theme.shape.borderRadius,
      },
      "&:first-of-type": {
        borderRadius: theme.shape.borderRadius,
      },
    },
  }));
  const dispatch = useDispatch();
  const [reload, setReload] = React.useState(true);
  const [reload2, setReload2] = React.useState(true);
  React.useEffect(() => {
    getPermission(dispatch);
  }, [dispatch, reload]);
  React.useEffect(() => {
    getAdmin(dispatch);
  }, [reload2]);
  const data = useSelector((state) => state.admin.allAdmin);
  const permissionData = useSelector((state) => state.admin.allPermissions);
  const permLoading = useSelector((state) => state.admin.loadingPermissions);

  const [email, setEmail] = React.useState(data[0]?.id);
  const permData = data
    .find((e) => e.id === email)
    ?.permissions.map((e) => e?.name);
  const [formats, setFormats] = React.useState(() => permData);

  React.useEffect(() => {
    const permData = data
      .find((e) => e.id === email)
      ?.permissions.map((e) => e?.name);
    setFormats(() => permData);
  }, [email]);
  console.log(email, formats);

  const handleFormat = (event, newFormats) => {
    setFormats(newFormats);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    AddPermission(dispatch, email, { permissions: formats });
    setEmail(data[0].id);
    setReload((prev) => !prev);
    setReload2((prev) => !prev);
  };
  return (
    <form onSubmit={handleSubmit}>
      <Card className={classes.card}>
        <div className={classes.selectContainer}>
          <label htmlFor="roles">Select Users</label>
          <select
            required
            id="roles"
            className={classes.selectItem}
            onInput={(e) => {
              const returnedValue = +e.target.value;
              setEmail(returnedValue);
            }}
          >
            {permLoading ? (
              <option>Loading...</option>
            ) : (
              data.map((e, i) => (
                <option key={i} value={e.id}>
                  {`${e?.name || "No Name"} - ${e.email || "No email"}`}
                </option>
              ))
            )}
          </select>
        </div>
        <span className={classes.selectModules}>Select modules</span>

        <div>
          {permLoading ? (
            <Loader />
          ) : (
            <StyledToggleButtonGroup
              value={formats}
              onChange={handleFormat}
              aria-label="text formatting"
              sx={{
                display: "flex",
                flexWrap: "wrap",
                width: 800,
              }}
            >
              {permissionData.map((e, i) => (
                <ToggleButton
                  //sx={btnstyles}
                  key={i}
                  value={e.name}
                  aria-label={e.name}
                >
                  {e.name}
                </ToggleButton>
              ))}
            </StyledToggleButtonGroup>
          )}
        </div>
        <div className={classes.btnContainer}>
          <button type="submit">Add Permissions</button>
        </div>
      </Card>
    </form>
  );
};

export default Permissions;

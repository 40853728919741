import React, { useEffect, useState } from "react";
import Card from "../../components/card/card";
import classes from "./add_terminal.module.css";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../../components/loader/loader";
import AddAgencyTerminal from "./AddAgencyTerminal";
import AddMerchantTerminal from "./AddMerchantTerminal";
import Toast from "../../../components/Toast/toast";

const operators = [
  'Agency',
  'Merchant'
];

const formInitState = {
  operator: 'agency'
}

const AddTerminal = () => {
  const [loading, setLoading] = useState(false);
  const [buttonLabel, setButtonLabel] = useState('');
  const [isLastStep, setIsLastStep] = useState(false);

  const [formData, setFormData] = useState(formInitState);

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!loading) return;

    const timeoutId = setTimeout(() => {
      setLoading(false);
      if (!isLastStep && formData?.operator?.toLowerCase() === 'merchant') {
        setIsLastStep(true);
      }
      else {
        Toast('Data saved successfully!', "success");
        resetForm();
      }
      
    }, 1000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [dispatch, loading]);


  const resetForm = () => {
    setFormData(formInitState)
    document.getElementById('AddTerminalForm')?.reset();
    setIsLastStep(false);
  }
  
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    console.log(formData);
  };

  const handleFormInput = (key: string, value: string) => {
    setFormData({ ...formData, [key]: value });
  }

  useEffect(() => {
    const operator = formData?.operator?.toLowerCase();
    if (operator === 'merchant') {
      if (isLastStep) {
        setButtonLabel('Save Changes');
      }
      else {
        setButtonLabel('Next');
      }
    }
    else {
      setButtonLabel('Save Changes');
    }
  }, [formData?.operator, isLastStep]);

  return (
    <form id='AddTerminalForm' onSubmit={handleSubmit} style={{ marginTop: 30 }}>
      <Card>
        <div>
          <p className={classes.header}>
            To add a new terminal, simply provide the airvend email address and admin role.
          </p>
          <div className={classes.container}>
            <select
              required
              id="operator"
              className={classes.containerInput}
              onInput={(e) => {
                handleFormInput('operator', e.target.value);
              }}
            >
              {operators.map((e, i) => (
                <option key={i} value={e}>
                  {e}
                </option>
              ))}
            </select>
            <label htmlFor="operator" className={classes.containerLabel}>
              Operator
            </label>
          </div>

          {
            formData?.operator?.toLowerCase() === 'agency'
            &&
            <AddAgencyTerminal formData={formData} setFormData={setFormData} />
          }

          {
            formData?.operator?.toLowerCase() === 'merchant'
            &&
            <AddMerchantTerminal isLastStep={isLastStep} formData={formData} setFormData={setFormData} />
          }
          <div style={{ marginTop: 32 }}>
            <button type="submit" className={classes.button} disabled={loading}>
              {
                loading ? <Loader /> : buttonLabel
              }
            </button>
          </div>
        </div>
      </Card>
    </form>
  );
};

export default AddTerminal;

import React from "react";
import Button from "../components/button/button";
import Card from "../components/card/card";

const TransactionPersonal = () => {
  return (
    <div>
      <Card className="box-card-details">
        <div className="details-header">User Details</div>
        <br />
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div className="details-main">
            <div className="space-rule">
              <div className="details-title">Full Name</div>
              <div className="details-text">Kenechukwu Ohiaeri</div>
            </div>
            <div className="space-rule">
              <div className="details-title">PHONE NUMBER</div>
              <div className="details-text">07012345678</div>
            </div>
            <div className="space-rule">
              <div className="details-title">EMAIL ADDRESS</div>
              <div className="details-text">Keneohiaeri@gmail.com</div>
            </div>
            <div className="space-rule">
              <div className="details-title">MERCHANT ID</div>
              <div className="details-text">BA9212320</div>
            </div>
            <div className="space-rule">
              <div className="details-title">RESIDENTIAL ADDRESS</div>
              <div className="details-text">
                Bon Hotels Grand Towers, Katampe, Abuja.
              </div>
            </div>
          </div>

          <img
            className="details-img"
            width="78"
            height="78"
            src="https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg"
            alt="img"
          />
        </div>

        <br />

        <Button
          btn_name="View ID Document"
          color="btn-default"
          customStyles={{
            border: "0.8px solid #1B0E44",
            backgroundColor: "#fff",
          }}
          loading={false}
          onClick={() => {}}
        />
      </Card>
    </div>
  );
};

export default TransactionPersonal;

import React from "react";
import "./checkbox.css";

interface Props {
  returningUser?: boolean;
  label?: JSX.Element;
  checked?: boolean;
  onClick?: any;
  mainClick?: () => void;
  labelStyle?: string;
}

export default function Checkbox(props: Props) {
  const { checked, label, onClick, labelStyle } = props;
  return (
    <div className="check-box" onClick={props?.mainClick}>
      {checked && (
        <input
          onChange={(e: any) => onClick(e.target.value)}
          type="checkbox"
          name=""
          id="check-box-id"
          checked
        />
      )}
      {!checked && (
        <input
          onChange={(e: any) => onClick(e.target.value)}
          type="checkbox"
          name=""
          id="check-box-id"
        />
      )}
      <label
        onClick={(e: any) => onClick(e)}
        htmlFor="check-box-id"
        className={`check-label ${labelStyle}`}
      >
        {label ? label : "Remember me"}
      </label>
    </div>
  );
}

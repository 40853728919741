import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
//import store from "../src/store/createStore";
import "antd/dist/antd.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./asset/css/main.css";
import "./asset/css/responsive.css";
import "./asset/css/animations.css";
import "./asset/css/navbar.css";
import "./index.css";
import store from "./NewPages/Redux/Store";

//import AppRoutes from "./routes";
import { Toaster } from "react-hot-toast";
import App from "./NewPages/App";
import { BrowserRouter } from "react-router-dom";

ReactDOM.render(
  <Provider store={store}>
    {/* <AppRoutes />*/}
    <BrowserRouter>
      <Toaster />
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

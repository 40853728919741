import { createSlice } from "@reduxjs/toolkit";

export const transactionSlice = createSlice({
  name: "transaction",
  initialState: {
    transaction: [],
    loading: false,
    totalpage: 0,
    perpage: 0,
  },
  reducers: {
    startTransaction: (state) => {
      state.loading = true;
    },
    fulfilledTransaction: (state, { payload }) => {
      state.loading = false;
      state.transaction = payload.data;
      state.totalpage = payload.total;
      state.perpage = payload.perpage;
    },
    errorTransaction: (state) => {
      state.loading = false;
    },
  },
});

export const { startTransaction, fulfilledTransaction, errorTransaction } =
  transactionSlice.actions;

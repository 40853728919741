import React from "react";
import classes from "../UserNavs/audit.module.css";
import Card from "../card/card";

const CardDetails = ({
  envname,
  merchandID,
  rid,
  settlementact,
  terminalid,
  lastActive,
}) => {
  return (
    <div className={`box-core ${classes.boxCore}`}>
      <Card className="box-card-details">
        <div className="details-main">
          <div className="space-rule">
            <div className="details-title">Environment Name</div>
            <div className="details-text">{envname}</div>
          </div>
          <div className="space-rule">
            <div className="details-title">Merchant ID</div>
            <div className="details-text">{merchandID}</div>
          </div>
          <div className="space-rule">
            <div className="details-title">Rid</div>
            <div className="details-text">{rid}</div>
          </div>
          <div className="space-rule">
            <div className="details-title">Settlement Account</div>
            <div className="details-text">{settlementact}</div>
          </div>
          <div className="space-rule">
            <div className="details-title">Terminal ID</div>
            <div className="details-text">{terminalid}</div>
          </div>
          <div className="space-rule">
            <div className="details-title">Last Active</div>
            <div className="details-text">{lastActive}</div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default CardDetails;

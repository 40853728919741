import React from 'react'
import './css/card.css'

interface Props {
    children?: any,
    className?: string
}

export default function Card(props: Props) {
    return (
        <div className={`card-custom ${props.className}`}>
            {props.children}
        </div>
    )
}

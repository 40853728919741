import { createSlice } from "@reduxjs/toolkit";

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    loadingData: false,
    loadingGraph: false,
    dashData: [],
    dashGraph: [],
  },
  reducers: {
    startDash: (state) => {
      state.loadingData = true;
      state.loadingGraph = true;
    },
    dashDataFulfilled: (state, { payload }) => {
      state.dashData = payload;
      state.loadingData = false;
    },
    dashGraphFulfilled: (state, { payload }) => {
      state.dashGraph = payload;
      state.loadingGraph = false;
    },
    dashFailed: (state) => {
      state.loadingData = false;
      state.loadingGraph = false;
    },
  },
});

export const { startDash, dashDataFulfilled, dashGraphFulfilled, dashFailed } =
  dashboardSlice.actions;

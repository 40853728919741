import React, { useState } from "react";
import Filter from "../components/Filter/Filter";
import { Space, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import classes from "./admin.module.css";
import { getAdmin, toggleAdmin } from "../Redux/Api/admin";

const ViewAdmin = () => {
  const dispatch = useDispatch();
  const [query, setQuery] = useState("");

  React.useEffect(() => {
    getAdmin(dispatch);
  }, [dispatch]);

  const adminData = useSelector((state) => state.admin.allAdmin);
  const loadingadmin = useSelector((state) => state.admin.loadingAllAdmin);

  const columns = [
    {
      title: "Email Address",
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => a.email > b.email,
    },
    {
      title: "Account Type",
      dataIndex: "accounttype",
      key: "accounttype",
      sorter: (a, b) => a.accounttype > b.accounttype,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name > b.name,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <Space size="middle">
          {status === "active" ? (
            <span className={classes.active}>{status}</span>
          ) : (
            <span className={classes.inactive}>{status}</span>
          )}
        </Space>
      ),
    },
    {
      title: "Last Login",
      dataIndex: "lastlogin",
      key: "lastlogin",
    },

    {
      title: "",
      key: "action",
      render: (key) => (
        <Space size="middle">
          {key.status === "active" ? (
            <button
              onClick={() =>
                toggleAdmin(dispatch, key.email, "deactivate", {
                  reason: "Not a management staff",
                })
              }
              className={classes.deactiveBtn}
            >
              Deactivate
            </button>
          ) : (
            <button
              onClick={() =>
                toggleAdmin(dispatch, key.email, "activate", {
                  reason: "New management staff",
                })
              }
              className={classes.activeBtn}
            >
              Activate
            </button>
          )}
        </Space>
      ),
    },
  ];
  const data = adminData.map((e) => ({
    key: e?.email,
    name: e?.name || "No name",
    lastlogin: new Date(e.updated_at).toDateString(),
    email: e?.email,
    status: e?.status,
    accounttype: e?.role,
  }));

  return (
    <div style={{ marginTop: 20 }}>
      <Filter setQuery={setQuery} data={data} />
      <Table columns={columns} dataSource={data} loading={loadingadmin} />
    </div>
  );
};

export default ViewAdmin;

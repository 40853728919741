import {
  startPosEnv,
  posEnvError,
  posEnvSuccess,
  addTerminalStart,
  addTerminalSuccess,
  addTerminalFailure,
  startTerminalFetch,
  fetchTerminalSuccess,
  fetchTerminalError
} from "../Slices/terminal";
import Toast from "../../../components/Toast/toast";
import api from "../../Lib/customAxios";

export const getPosEnvironment = async (dispatch) => {
  dispatch(startPosEnv());
  try {
    const res = await api.get("/terminal/environments");
    if (res.data.code === 200 && res.data.status === "success") {
      return dispatch(posEnvSuccess(res.data.data));
    }
    if (res.data.data.code === 401) {
      localStorage.removeItem("TOKEN");
      window.location.href = "/";
    } else {
      dispatch(posEnvError());
    }
  } catch (error) {
    dispatch(posEnvError());
  }
};

export const addTerminal = async (dispatch, id, payload) => {
  dispatch(addTerminalStart());
  try {
    const res = await api.post(`/customers/${id}/allocate-terminal`, payload);
   
    if (res.data.code === 200 && res.data.status === "success") {
      dispatch(addTerminalSuccess());
      Toast(res.data.message, "success");
    }
    if (res.data.code === 400) {
      dispatch(addTerminalFailure());
      return Toast(res.data.message, "error");
    }
    if (res.data.data.code === 401) {
      localStorage.removeItem("TOKEN");
      window.location.href = "/";
    } else {
      Toast(res.data.message, "error");
      dispatch(addTerminalFailure());
    }
  } catch (error) {
    dispatch(addTerminalFailure());
  }
};

export const fetchAllTerminals = async (dispatch, page = 1) => {
  dispatch(startTerminalFetch());
  try {
    const res = await api.get("/terminals?page=" + page);
    if (res.data.code === 200 && res.data.status === "success") {
      console.log("API Data", res.data.data);
      return dispatch(fetchTerminalSuccess(res.data.data));
    }
    if (res.data.data.code === 401) {
      Toast(res.data.data.message, "error");
      localStorage.removeItem("TOKEN");
      window.location.href = "/";
    } else {
      Toast(res.data.data.message, "error");
      dispatch(fetchTerminalError());
    }
  } catch (error) {
    dispatch(fetchTerminalError());
  }
};

export const fetchAssignedTerminals = async (dispatch, page = 1) => {
  dispatch(startTerminalFetch());
  try {
    const res = await api.get("/terminals/assigned?page=" + page);
    if (res.data.code === 200 && res.data.status === "success") {
      return dispatch(fetchTerminalSuccess(res.data.data));
    }
    if (res.data.data.code === 401) {
      localStorage.removeItem("TOKEN");
      window.location.href = "/";
    } else {
      dispatch(fetchTerminalError());
    }
  } catch (error) {
    dispatch(fetchTerminalError());
  }
};

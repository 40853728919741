import React, { useState } from "react";
import SettingsNav from "../../components/setting-nav/setting-nav";
import AddTerminal from "./AddTerminal/AddTerminal";
import TerminalListing from "./TerminalListing";
import AssignedTerminalListing from "./AssignedTerminalListing";
import UploadBulkTerminal from "./UploadBulkTerminal";

const settingNavContent = [
  {
    state: "settings-active",
    name: "All Terminals",
    path: "/settings-Terminals All",
    key: "terminal_listing"
  },
  {
    state: "settings-inactive",
    name: "Assigned Terminals",
    path: "/settings-Terminals Assigned",
    key: "assigned_terminal_listing"
  },
  {
    state: "settings-inactive",
    name: "Add Terminal",
    path: "/settings-Terminals Add",
    key: "add_terminal",
  },
  {
    state: "settings-inactive",
    name: "Bulk Terminals",
    path: "/settings-Terminals Bulk",
    className: 'pull-right',
    key: 'upload_bulk_terminals'
  },
];

function Terminals() {
  const [settingsPage, setSettingsPage] = useState<string | undefined>('terminal_listing');
  return (
    <div className="settings-box-management">
      <SettingsNav
        navContent={settingNavContent}
        handleClick={(index, key) => {
          setSettingsPage(key);
        }}
      />

      <div className="settings-pseudo-body">
        {settingsPage === "terminal_listing" && <TerminalListing />}
        {settingsPage === "assigned_terminal_listing" && <AssignedTerminalListing />}
        {settingsPage === "add_terminal" && <AddTerminal />}
        {settingsPage === "upload_bulk_terminals" && <UploadBulkTerminal />}
      </div>

      <br />
      <br />
    </div>
  );
}

export default Terminals;

import React from "react";
import classes from "./Wallet.module.css";
import { useDispatch } from "react-redux";
import { getWallet } from "../../Redux/Api/wallet";

const data = [
  "MAIN_WALLET",
  "COMMISSION_WALLET",
  "AIRPAY_WALLET",
  "INCENTIVE_WALLET",
];
const Wallet = ({ id }) => {
  const dispatch = useDispatch();
  const [wallet, setWallet] = React.useState(data[0]);
  const [amount, setAmount] = React.useState("");
  const [reason, setReason] = React.useState("");
  const [type, setType] = React.useState(null);

  const submitForm = (e) => {
    e.preventDefault();
    getWallet(dispatch, { wallet, amount: +amount, reason, type, id });

    setType(null);
    setReason("");
    setAmount("");
  };
  return (
    <div className={classes.container}>
      <form onSubmit={submitForm}>
        <div className={classes.formContainer}>
          <div className={classes.selectContainer}>
            <label htmlFor="wallet">Select Wallet</label>
            <select
              id="wallet"
              className={classes.selectItem}
              onInput={(e) => {
                setWallet(e.target.value);
              }}
            >
              {data.map((e, i) => (
                <option key={i} value={e}>
                  {e}
                </option>
              ))}
            </select>
          </div>
          <div className={classes.selectContainer}>
            <label htmlFor="amount">Amount</label>
            <input
              id="amount"
              type="text"
              placeholder="Amount"
              value={amount}
              onChange={(e) => {
                setAmount(e.target.value);
              }}
              className={classes.selectItem}
              required
            />
          </div>
          <div className={classes.selectContainer}>
            <label htmlFor="reason">Reason</label>
            <input
              id="reason"
              type="text"
              placeholder="Reason"
              value={reason}
              onChange={(e) => {
                setReason(e.target.value);
              }}
              className={classes.selectItem}
              required
            />
          </div>
        </div>
        <div className={classes.buttonContainer}>
          <button
            className={classes.btn1}
            type="submit"
            onClick={() => setType("credit")}
          >
            Credit Wallet
          </button>
          <button
            className={classes.btn2}
            type="submit"
            onClick={() => setType("debit")}
          >
            Debit Wallet
          </button>
        </div>
      </form>
    </div>
  );
};

export default Wallet;

import React, { useEffect, useState } from "react";
import Filter from "../components/Filter/Filter";
import classes from "./Kyc.module.css";
import { getKyc } from "../Redux/Api/kyc.js";
import { Space, Table } from "antd";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PageHeader from "../../components/PageHeader/PageHeader";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { getSuperAgents } from "../Redux/Api/upgradeTier";

const Kyc = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState(false);

  const kycdata = useSelector((state) => state.kyc.kycs);
  const loading = useSelector((state) => state.kyc.loading);
  const totalPage = useSelector((state) => state.kyc.totalpage);
  const perpage = useSelector((state) => state.kyc.perpage);

  //const keys = ["timestamp", "fullname", "email"];
  // const search = (data) => {
  //   return data.filter((item) =>
  //     keys.some((key) => item[key].toLowerCase().includes(query))
  //   );
  // };

  useEffect(() => {
    history.push(`/kyc?page=${page}`);
    getKyc(dispatch, page);
    getSuperAgents(dispatch);
  }, [dispatch, page]);

  const columns = [
    {
      title: "Time Stamp",
      dataIndex: "timestamp",
      key: "timestamp",
      sorter: (a, b) => a.timestamp > b.timestamp,
    },
    {
      title: "Full Name",
      dataIndex: "fullname",
      key: "fullname",
    },
    {
      title: "Email Address",
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => a.email > b.email,
    },
    {
      title: "Tier Level",
      dataIndex: "tierlevel",
      key: "tierlevel",
      filters: [
        {
          text: "Tier 1",
          value: "Tier 1",
        },
        {
          text: "Tier 2",
          value: "Tier 2",
        },
        {
          text: "Tier 3",
          value: "Tier 3",
        },
        {
          text: "Tier 4",
          value: "Tier 4",
        },
        {
          text: "Tier 5",
          value: "Tier 5",
        },
      ],
      onFilter: (value, record) => record.tierlevel.includes(value),
    },

    {
      title: "",
      key: "action",
      render: (key) => (
        <Space size="middle" className={classes.review}>
          <Link to={`/kyc/${key.key}`}>Review Kyc</Link>
        </Space>
      ),
    },
  ];

  const data = kycdata.map((e) => ({
    key: e?.account_id,
    fullname: e?.user_data?.name || "No name",
    timestamp: moment(e?.created_at).format("LLL"),
    email: e?.email,
    tierlevel: `Tier ${e?.user_type}`,
  }));

  const kycData = useSelector((state) => state.kycPersonal.kycData);
  const newkycsData = [
    {
      key: kycData?.account_id,
      fullname: kycData?.user_data?.name || "No name",
      timestamp: moment(kycData?.created_at).format("LLL"),
      email: kycData?.email,
      tierlevel: `Tier ${kycData?.user_type}`,
    },
  ];

  const kycLoading = useSelector((state) => state.kycPersonal.loading);
  return (
    <>
      <PageHeader heading="KYC Submissions" />
      <Filter setQuery={setQuery} data={data} type="kyc" />

      {query ? (
        <Table
          columns={columns}
          dataSource={newkycsData}
          loading={kycLoading}
        />
      ) : (
        <Table
          columns={columns}
          dataSource={data}
          loading={loading}
          pagination={{
            pageSize: perpage,
            total: totalPage,
            onChange: (page) => {
              setPage(page);
            },
          }}
        />
      )}
    </>
  );
};

export default Kyc;

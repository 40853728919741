import { configureStore } from "@reduxjs/toolkit";
import { authSlice } from "../Slices/auth";
import { userSlice } from "../Slices/user";
import { kycSlice } from "../Slices/kyc";
import { kycPersonal } from "../Slices/kycPersonal";
import { transTypeSlice } from "../Slices/transType";
import { transactionSlice } from "../Slices/transaction";
import { userPersonal } from "../Slices/userPersonal";
import { dashboardSlice } from "../Slices/dashboard";
import { rolesSlice } from "../Slices/roles";
import { adminSlice } from "../Slices/admin";
import { serviceSlice } from "../Slices/service";
import { terminalSlice } from "../Slices/terminal";
import { auditSlice } from "../Slices/audit";

const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    user: userSlice.reducer,
    kyc: kycSlice.reducer,
    roles: rolesSlice.reducer,
    admin: adminSlice.reducer,
    audit: auditSlice.reducer,
    terminal: terminalSlice.reducer,
    service: serviceSlice.reducer,
    dashboard: dashboardSlice.reducer,
    kycPersonal: kycPersonal.reducer,
    userPersonal: userPersonal.reducer,
    transType: transTypeSlice.reducer,
    transaction: transactionSlice.reducer,
  },
});

export default store;

import ContentLayout from "../ContentLayout/ContentLayout";
import NavBar from "../NavBar/NavBar";
import classes from "./MainLayout.module.css";
import { Route, Switch } from "react-router-dom";
import Kyc from "../Kyc/Kyc";
import KycPersonal from "../Kyc/KycPersonal/KycPersonal.tsx";
import Users from "../Users/Users";
import UserNavContent from "../Users/UserNavContent";
import TransactionPersonal from "../TransactionOverview/TransactionPersonal";
import Dashoverview from "../Dashoverview/Dashoverview";
import Auditlogs from "../AuditLogs/Auditlogs";
import ServiceProviders from "../ServiceProviders/ServiceProviders";
import Notifications from "../Notifications/Notifications";
import Admin from "../Admin/Admin";
import Terminals from "../Terminals/Terminals";

import {
  viewUserRoute,
  auditLogRoute,
  addTerminalRoute,
  viewKycRoute,
  viewProvidersRoute,
  viewAdminRoute,
  viewNotificationsRoute,
} from "../Lib/routing";


const MainLayout = () => {
  const roles = JSON.parse(localStorage.getItem("ROLES"));
  const viewUser = viewUserRoute(roles);
  const auditLog = auditLogRoute(roles);
  const viewAdmin = viewAdminRoute(roles);
  const addTerminal = addTerminalRoute(roles);
  const viewKyc = viewKycRoute(roles);
  const viewProviders = viewProvidersRoute(roles);
  const viewNotifications = viewNotificationsRoute(roles);
  return (
    <div className={classes.navContainer}>
      <NavBar />

      <Switch>
        <Route path="/" exact>
          <ContentLayout>
            <Dashoverview />
          </ContentLayout>
        </Route>
        {addTerminal && (
          <Route path="/terminals" exact>
            <ContentLayout>
              <Terminals />
            </ContentLayout>
          </Route>
        )}
        {viewKyc && (
          <Route path="/kyc" exact>
            <ContentLayout>
              <Kyc />
            </ContentLayout>
          </Route>
        )}
        {viewUser && (
          <Route path="/users" exact>
            <ContentLayout>
              <Users />
            </ContentLayout>
          </Route>
        )}
        {viewAdmin && (
          <Route path="/admin-management" exact>
            <ContentLayout>
              <Admin />
            </ContentLayout>
          </Route>
        )}
        {auditLog && (
          <Route path="/audit-logs" exact>
            <ContentLayout>
              <Auditlogs />
            </ContentLayout>
          </Route>
        )}
        {viewProviders && (
          <Route path="/service-providers" exact>
            <ContentLayout>
              <ServiceProviders />
            </ContentLayout>
          </Route>
        )}
        {viewNotifications && (
          <Route path="/notifications" exact>
            <ContentLayout>
              <Notifications />
            </ContentLayout>
          </Route>
        )}
        <Route path="/kyc/:userid" exact>
          <ContentLayout>
            <KycPersonal />
          </ContentLayout>
        </Route>
        <Route path="/users/:userid" exact>
          <ContentLayout>
            <UserNavContent />
          </ContentLayout>
        </Route>
        <Route path="/transactions-overview/:userid" exact>
          <ContentLayout>
            <TransactionPersonal />
          </ContentLayout>
        </Route>
        {/* <Route path="/*">Nothing here</Route> */}
      </Switch>
    </div>
  );
};

export default MainLayout;

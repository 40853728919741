import React, { useState, useEffect } from "react";
import PageHeader from "../../../components/PageHeader/PageHeader";
import BusinessDetails from "../../components/kyc-navs/BusinessDetail/BusinessDetail";
import PersonalDetails from "../../components/kyc-navs/PersonalDetail/PersonalDetail";
import TierLevel from "../../components/kyc-navs/TierLevel/TierLevel";
import SettingsNav from "../../components/SettingNav/SettingNav";
import classes from "./KycPersonal.module.css";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getKycPersonal } from "../../Redux/Api/kycPersonal";
import Loader from "../../components/Loader/Loader";

const settingNavContent = [
  {
    state: "settings-active",
    name: "Personal Details",
    path: "/settings-Profile Details",
  },
  {
    state: "settings-inactive",
    name: "Enterprise Details",
    path: "/settings-Business Details",
  },
  {
    state: "settings-inactive",
    name: "Tier Level",
    path: "/settings-Banking Details",
  },
];

function KycPersonal() {
  const { userid }: any = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    getKycPersonal(dispatch, userid);
  }, [dispatch, userid]);

  const data = useSelector((state: any) => state.kycPersonal.kycData);
  const loading = useSelector((state: any) => state.kycPersonal.loading);

  console.log(data);
  const [settingPage, setsettingPage] = useState(() => 0);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className={classes.settingBox}>
          <PageHeader heading={data?.user_data?.name || "No Name"} to="/kyc" />
          <SettingsNav
            navContent={settingNavContent}
            handleClick={(index: number) => {
              setsettingPage((prev) => index);
            }}
          />

          <div className={classes.settingBody}>
            {settingPage === 0 && <PersonalDetails data={data} />}
            {settingPage === 1 && <BusinessDetails data={data} />}
            {settingPage === 2 && <TierLevel data={data?.account_id} />}
          </div>

          <br />
          <br />
        </div>
      )}
    </>
  );
}

export default React.memo(KycPersonal);

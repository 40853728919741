import React, { useEffect } from "react";
import classes from "./add_terminal.module.css";



interface AddAgencyTerminalProps {
  formData: any,
  setFormData: any
}

const formInitState = {
  agent_id: '', slipper_footer: '', slipper_header: ''
};

const AddAgencyTerminal = ({formData, setFormData} : AddAgencyTerminalProps) => {

  const handleFormInput = (key: string, value: string) => {
    setFormData({ ...formData, [key]: value });
  }

  useEffect(() => {
    setFormData({...formData, ...formInitState});
  }, []);

  return (
    <>
      <div className={classes.container}>
        <input
          id="agent_id"
          type="text"
          value={formData?.agent_id}
          onChange={(e) => {
            handleFormInput('agent_id', e.target.value);
          }}
          placeholder="Agent ID"
          className={classes.containerInput}
          required
        />

        <label htmlFor="agent_id" className={classes.containerLabel}>
          Agent ID
        </label>
      </div>
      <div className={classes.container}>
        <input
          type="text"
          value={formData?.slipper_header}
          onChange={(e) => handleFormInput('slipper_header', e.target.value)}
          placeholder="Slipper Header"
          id="slipper_header"
          className={classes.containerInput}
          required
        />

        <label htmlFor="slipper_header" className={classes.containerLabel}>
          Slipper Header
        </label>
      </div>

      <div className={classes.container}>
        <input
          type="text"
          value={formData?.slipper_footer}
          onChange={(e) => handleFormInput('slipper_footer', e.target.value)}
          placeholder="Slipper Footer"
          id="slipper_footer"
          className={classes.containerInput}
          required
        />

        <label htmlFor="slipper_footer" className={classes.containerLabel}>
          Slipper Footer
        </label>
      </div>
    </>
  );
};

export default AddAgencyTerminal;

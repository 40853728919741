import { createSlice } from "@reduxjs/toolkit";

export const serviceSlice = createSlice({
  name: "service",
  initialState: {
    service: [],
    loading: false,
    loadingAdd: false,
  },
  reducers: {
    startService: (state) => {
      state.loading = true;
    },
    fulfilledService: (state, { payload }) => {
      state.service = payload;
      state.loading = false;
    },
    errorService: (state) => {
      state.loading = false;
    },
    startAddService: (state) => {
      state.loadingAdd = true;
    },
    errorAddService: (state) => {
      state.loadingAdd = false;
    },
  },
});

export const {
  startService,
  fulfilledService,
  errorService,
  startAddService,
  errorAddService,
} = serviceSlice.actions;

import React, { useEffect, useState } from "react";
import classes from "./ServiceProviders.module.css";
import Filter from "../components/Filter/Filter";
import PageHeader from "../components/PageHeader/PageHeader";
import { useDispatch, useSelector } from "react-redux";

import { getService } from "../Redux/Api/service";
import Loader from "../components/Loader/Loader";
import Modal from "../Modal/Modal";
import AddProvider from "./AddProvider";
import EditProvider from "./EditProvider";

interface Props {
  src?: string;
  status?: string;
  name?: string;
  id?: any;
}

function ServiceProviders() {
  const [show, setShow] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [editData, setEditData] = useState(null);
  const dispatch = useDispatch();
  const data = useSelector((state) => state.service.service);
  const loading = useSelector((state) => state.service.loading);

  const editDataFn = (id: any) => {
    const newData = data.find((e: any) => e.id === id);
    setEditData(newData);
  };

  const Services = ({ src, status, name, id }: Props) => {
    return (
      <div className={classes.serviceContainer}>
        <div className={classes.telco}>
          <img src={src} alt="Service Providers" />
        </div>
        <h3>{name}</h3>
        {status === "active" ? (
          <div className={classes.buttonActive}>
            <p>Active</p>
          </div>
        ) : (
          <div className={classes.buttonInactive}>
            <p>Inactive</p>
          </div>
        )}
        <div className={classes.buttonContainer}>
          <button
            className={classes.buttonDelete}
            onClick={() => {
              console.log(id);
            }}
          >
            Delete
          </button>
          <button
            className={classes.buttonEdit}
            onClick={() => {
              editDataFn(id);
              setShow(true);
            }}
          >
            Edit Service
          </button>
        </div>
      </div>
    );
  };

  useEffect(() => {
    getService(dispatch);
  }, [dispatch]);
  return (
    <>
      <PageHeader heading="Service Providers" />
      <Filter
        service="Add Service Provider"
        showModal={setShow}
        showAdd={setShowAdd}
        setQuery={setEditData}
      />
      <div className={classes.servicesMain}>
        {loading ? (
          <Loader />
        ) : (
          data.map((e: any) => (
            <Services
              key={e.id}
              id={e.id}
              src={e?.image_url}
              status={e?.status}
              name={e?.name}
            />
          ))
        )}
        {show && (
          <Modal>
            {showAdd ? (
              <AddProvider setShow={setShow} showAdd={setShowAdd} />
            ) : (
              <EditProvider setShow={setShow} dataSource={editData} />
            )}
          </Modal>
        )}
      </div>
    </>
  );
}

export default ServiceProviders;

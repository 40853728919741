import React from "react";
import "./tiles.css";
//import Loader from '../loader/loader';

interface TilesProps {
  title: string;
  currency?: string;
  boldMid: any;
  bottomElement?: JSX.Element;
  iconSelf: JSX.Element;
}

const Tiles: React.FC<TilesProps> = ({
  title,
  currency,
  boldMid,
  bottomElement,
  iconSelf,
}) => {
  //currency = currency ? currency : "";

  return (
    <>
      <div className="tileContainer">
        <div className="left-container">
          <div className="title-item" style={{ color: "#9B9B9B" }}>
            {title}
          </div>

          <div style={{ height: 10 }} />

          <div className="mid-item">{currency}</div>

          <br />

          <div className="jsx-item">{bottomElement}</div>
        </div>
        <div className="right-container">{iconSelf}</div>
      </div>
    </>
  );
};

export default Tiles;

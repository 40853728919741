import {
  fetchError,
  fetchFulfilled,
  fetchStart,
  activateUsers,
} from "../Slices/user";
import api from "../../Lib/customAxios";
import Toast from "../../../components/Toast/toast";

export const getUsers = async (dispatch, page) => {
  dispatch(fetchStart());
  try {
    const res = await api.get(`/accounts?page=${page}`);
    if (res.data.code === 200 && res.data.status === "success") {
      dispatch(
        fetchFulfilled({
          data: res.data.data.data,
          total: res.data.data.total,
          perpage: res.data.data.per_page,
        })
      );
    }
    if (res.data.code === 401) {
      localStorage.removeItem("TOKEN");
      window.location.href = "/";
    } else {
      dispatch(fetchError());
    }
  } catch (error) {
    dispatch(fetchError());
    console.log(error.response);
  }
};

export const toggleUser = async (dispatch, id, type, reason) => {
  try {
    const res = await api.post(`/accounts/${id}/${type}`, reason);
    if (res.data.code === 200 && res.data.status === "success") {
      Toast(res.data.message, "success");
      return dispatch(activateUsers(id));
    }
    if (res.data.code === 401) {
      localStorage.removeItem("TOKEN");
      window.location.href = "/";
    } else {
      dispatch(fetchError());
      Toast(res.data.message, "error");
    }
  } catch (error) {
    dispatch(fetchError());
  }
};

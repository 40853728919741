import Toast from "../../../components/Toast/toast";
import { loginStart, loginSuccess, loginError } from "../Slices/auth";
import axios from "axios";
import api from "../../Lib/customAxios";

export const loginAuth = async (payload, dispatch) => {
  dispatch(loginStart());
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/auth/login`,
      payload
    );
    if (res.data && res.data.code === 200) {
      localStorage.setItem("TOKEN", res.data.data.access_token);
      const res2 = await api.get("/account/me", payload);
      const roles = res2.data.data.permissions.map((e) => e.name);
      localStorage.setItem("ROLES", JSON.stringify(roles));
      dispatch(loginSuccess());
      Toast(res.data.message, "success");
    } else {
      Toast(res.data.message, "error");
    }
  } catch (error) {
    dispatch(loginError());
  }
};

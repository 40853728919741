import React from "react";
import Card from "../card/card";
import classes from "./audit.module.css";

const TransDetails = ({ data }) => {
  console.log(data);
  return (
    <Card className={classes.scrollableCard}>
      <div className="details-main">
        {Object.keys(data)?.map((key, i) => (
          <div
            key={i}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <span className="details-title" style={{ marginRight: 3 }}>
              {key}:
            </span>
            <span className="details-text">{data[key]}</span>
          </div>
        ))}
      </div>
    </Card>
  );
};

export default TransDetails;

import {
  startRolesFetch,
  rolesFetchSuccess,
  rolesFetchError,
  addRoleStart,
  addRoleFailure,
  addRoleSuccess,
} from "../Slices/roles";
import Toast from "../../../components/Toast/toast";
import api from "../../Lib/customAxios";

export const getRoles = async (dispatch) => {
  dispatch(startRolesFetch());
  try {
    const res = await api.get("/roles");
    if (res.data.code === 200 && res.data.status === "success") {
      return dispatch(rolesFetchSuccess(res.data.data));
    }
    if (res.data.data.code === 401) {
      localStorage.removeItem("TOKEN");
      window.location.href = "/";
    } else {
      dispatch(rolesFetchError());
    }
  } catch (error) {
    dispatch(rolesFetchError());
  }
};

export const addRole = async (dispatch, payload) => {
  dispatch(addRoleStart());
  try {
    const res = await api.post("/account/create", payload);
    if (res.data.code === 200 && res.data.status === "success") {
      dispatch(addRoleSuccess());
      Toast(res.data.message, "success");
    }
    if (res.data.data.code === 401) {
      localStorage.removeItem("TOKEN");
      window.location.href = "/";
    } else {
      Toast(res.data.message, "error");
      dispatch(addRoleFailure());
    }
  } catch (error) {
    dispatch(addRoleFailure());
  }
};

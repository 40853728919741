import { startKyc, fulfilledKyc, errorKyc } from "../Slices/kyc";
import api from "../../Lib/customAxios";

export const getKyc = async (dispatch, page) => {
  dispatch(startKyc());
  try {
    const res = await api.get(`/kycs?page=${page}`);
    if (res.data.code === 200 && res.data.status === "success") {
      return dispatch(
        fulfilledKyc({
          data: res.data.data.data,
          total: res.data.data.total,
          perpage: res.data.data.per_page,
        })
      );
    }
    if (res.data.code === 401) {
      localStorage.removeItem("TOKEN");
      window.location.href = "/";
    } else {
      dispatch(errorKyc());
    }
  } catch (error) {
    dispatch(errorKyc());
  }
};

import React from "react";
import ReactDOM from "react-dom";
import classes from "./Modal.module.css";
import Card from "../components/card/card";

const Backdrop = (props) => {
  return <div className={classes.backdrop} />;
};

const Modal = (props) => {
  return (
    <div>
      <>
        {ReactDOM.createPortal(
          <Backdrop onConfirm={props.onConfirm} />,
          document.getElementById("backdrop-root")
        )}
        {ReactDOM.createPortal(
          <Card className={classes.modal}>{props.children}</Card>,
          document.getElementById("overlay-root")
        )}
      </>
    </div>
  );
};

export default Modal;

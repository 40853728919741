import { startFetch, fulfilledFetch, errorFetch } from "../Slices/userPersonal";

import api from "../../Lib/customAxios";

export const getUserPersonal = async (dispatch, id) => {
  dispatch(startFetch());
  try {
    const res = await api.get(`/accounts/${id}`);

    if (res.data.code === 200 && res.data.status === "success") {
      dispatch(fulfilledFetch(res.data.data));
    }
    if (res.code === 401) {
      localStorage.removeItem("TOKEN");
      window.location.href = "/";
    } else {
      dispatch(errorFetch());
    }
  } catch (error) {
    dispatch(errorFetch());
    console.log(error);
  }
};

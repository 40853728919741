import React, {useState} from "react";
import Filter from "../components/Filter/Filter";
import { Space, Table } from "antd";
import { Link } from "react-router-dom";
import classes from "./Terminal.module.css";

import { fetchAssignedTerminals } from "../Redux/Api/terminal";
import { useDispatch, useSelector } from "react-redux";

const AssignedTerminalListing = () => {
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();

  React.useEffect(() => {
    fetchAssignedTerminals(dispatch);
  }, [dispatch]);

  const terminalData = useSelector((state) => state.terminal.assignedTerminals);
  const loadingTerminals = useSelector((state) => state.terminal.loading);
  const data: any[] = terminalData?.data?? [];

  const columns: any[] = [
    {
      title: "Terminal ID",
      dataIndex: "terminal_id",
      key: "terminal_id",
      sorter: (a: any, b: any) => a.id - b.id,
    },
    {
      title: "Assigned To",
      dataIndex: "merchant_data",
      key: "merchant_data",
      render: (item: any) => item?.merchant_name
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: (a: any, b: any) => a.status > b.status,
    },
    {
      title: "Last Active",
      dataIndex: "last_active",
      key: "last_active",
    },

  ];
  
  return (
    <>
      <div style={{ margin: "3% 0" }}>
        <Filter />
      </div>

      
      <Table 
        columns={columns} 
        dataSource={terminalData?.data?? []} 
        loading={loadingTerminals} 
        pagination={{
          total: terminalData?.total?? 1,
          current: terminalData?.current_page,
          onChange: (page)  => {
            setPage(page);
          }
        }}
      />
    </>
  );
};

export default AssignedTerminalListing;

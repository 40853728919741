import React from "react";
//import classes from "./Auditlogs.module.css";
import Filter from "../components/Filter/Filter";
import { Table } from "antd";
import PageHeader from "../components/PageHeader/PageHeader";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getAudit } from "../Redux/Api/audit";
import moment from "moment";

const Auditlogs = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(1);

  const auditdata = useSelector((state) => state.audit.audit);
  const loading = useSelector((state) => state.audit.loading);
  const totalPage = useSelector((state) => state.audit.totalpage);
  const perpage = useSelector((state) => state.audit.perpage);

  React.useEffect(() => {
    history.push(`/audit-logs?page=${page}`);
    getAudit(dispatch, page);
  }, [dispatch, page]);

  const columns = [
    {
      title: "Timestamp",
      dataIndex: "timestamp",
      key: "timestamp",
      sorter: (a, b) => a.timestamp - b.timestamp,
    },
    {
      title: "Admin Name",
      dataIndex: "adminname",
      key: "adminname",
      sorter: (a, b) => a.adminname - b.adminname,
    },
    {
      title: "Admin Role",
      dataIndex: "adminrole",
      key: "adminrole",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
    },
  ];
  const data = auditdata.map((e) => ({
    key: e?.id,
    timestamp: moment(e.created_at).format("LLL"),
    adminname: e?.user?.name,
    adminrole: e?.user?.role,
    action: e?.action,
  }));

  return (
    <>
      <PageHeader heading="Audit logs" />
      <Table
        columns={columns}
        dataSource={data}
        loading={loading}
        pagination={{
          pageSize: perpage,
          total: totalPage,
          onChange: (page) => {
            setPage(page);
          },
        }}
      />
    </>
  );
};

export default Auditlogs;

import React, { useState } from "react";
import Button from "../../button/button";
import Card from "../../card/card";
import Dropdown from "../../dropdown/dropdown";
import { Input } from "antd";
import classes from "./TierLevel.module.css";
import { upgradeTier } from "../../../Redux/Api/upgradeTier";
import { useSelector } from "react-redux";

function TierLevel({ data }: any) {
  const { TextArea } = Input;
  const [tier, setTier] = useState("");
  const [agent, setAgent] = useState("");
  const [text, setText] = useState("");

  const superAgentdata = useSelector((state) => state.kyc.superAgents).map(
    (e: any) => ({
      id: e.account_id,
      name: `${e?.user_data?.name} - ${e?.email}`,
    })
  );

  const handleTierClick = async () => {
    let payload;
    if (tier === "4") {
      payload = {
        tier: tier,
        comment: text,
        super_account_id: agent,
      };
    } else {
      payload = {
        tier: tier,
        comment: text,
      };
    }
    upgradeTier(data, payload);
    setText("");
  };

  return (
    <div className={classes.boxCore}>
      <Card className={classes.inputCard}>
        <form action="/">
          <Dropdown
            label="Select Tier Level"
            valueKey={"id"}
            displayKey={"name"}
            data={[
              { id: 1, name: "Tier 1" },
              { id: 2, name: "Tier 2" },
              { id: 3, name: "Tier 3" },
              { id: 4, name: "Tier 4" },
              { id: 5, name: "Tier 5" },
            ]}
            onInput={(e) => {
              setTier(e.target.value);
            }}
          />
          <div style={{ height: 40 }} />
          {tier === "4" && (
            <>
              <Dropdown
                label="Select Super Agent"
                valueKey={"id"}
                displayKey={"name"}
                data={superAgentdata}
                onInput={(e) => {
                  setAgent(e.target.value);
                }}
              />
              <div style={{ height: 40 }} />
            </>
          )}
          <TextArea
            className={classes.inputCard}
            rows={5}
            placeholder="Comment is a must"
            value={text}
            required={true}
            onChange={(e) => setText(e.target.value)}
          />

          <div style={{ height: 40 }} />

          <Button
            id="myBtn"
            btn_name="Save Changes"
            color="btn-blue"
            onClick={handleTierClick}
          />
        </form>
      </Card>
    </div>
  );
}

export default React.memo(TierLevel);

import api from "../../Lib/customAxios";
import {
  errorTransaction,
  fulfilledTransaction,
  startTransaction,
} from "../Slices/transaction";

export const getTransaction = async (dispatch, id, type, page) => {
  dispatch(startTransaction());
  try {
    const res = await api.get(
      `accounts/${id}/transactions/${type}?page=${page}`
    );
    if (res.data.code === 200 && res.data.status === "success") {
      return dispatch(
        fulfilledTransaction({
          data: res.data.data.data,
          total: res.data.data.total,
          perpage: res.data.data.per_page,
        })
      );
    }
    if (res.data.code === 401) {
      localStorage.removeItem("TOKEN");
      window.location.href = "/";
    } else {
      dispatch(errorTransaction());
    }
  } catch (error) {
    dispatch(errorTransaction());
  }
};

import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    authStatus: false,
    pending: false,
    error: false,
  },
  reducers: {
    loginStart: (state) => {
      state.pending = true;
    },
    loginSuccess: (state) => {
      state.pending = false;
      state.authStatus = true;
    },
    loginError: (state) => {
      state.error = true;
    },
    logout: (state) => {
      state.authStatus = false;
      localStorage.removeItem("TOKEN");
      window.location.href = "/";
    },
  },
});

export const { loginStart, loginSuccess, loginError, logout } =
  authSlice.actions;

import React, {useState} from "react";
import Filter from "../components/Filter/Filter";
import { Modal, Space, Table } from "antd";
import { Link } from "react-router-dom";
import classes from "./Terminal.module.css";
import { fetchAllTerminals } from "../Redux/Api/terminal";
import { useDispatch, useSelector } from "react-redux";
import ViewSelectedTerminalInfo from "./ViewSelectedTerminalInfo";


const TerminalListing = () => {
  const [showTerminalInfoModal, setShowTerminalInfoModal] = useState(false);
  const [page, setPage] = useState(1);

  const dispatch = useDispatch();

  React.useEffect(() => {
    fetchAllTerminals(dispatch, page);
  }, [dispatch, page]);

  const terminalData = useSelector((state) => state.terminal.terminals);
  const loadingTerminals = useSelector((state) => state.terminal.loading);

  const columns: any[] = [
    {
      title: "Terminal ID",
      dataIndex: "terminal_id",
      key: "terminal_id",
      sorter: (a: any, b: any) => a.id - b.id,
    },
    {
      title: "Merchant ID",
      dataIndex: "merchant_id",
      key: "merchant_id",
    },
    {
      title: "Merchant Name",
      dataIndex: "merchant_data",
      key: "merchant_data",
      render: (item: any) => item?.merchant_name
    },
    {
      title: "Date Created",
      dataIndex: "merchant_data",
      key: "merchant_data",
      sorter: (a: any, b: any) => a.date_created > b.date_created,
      render: (item: any) => item?.created_at
    },

    {
      title: "",
      key: "action",
      render: (item: any) => (
        <Space size="middle" className={classes.review}>
          <Link to='#' onClick={() => setShowTerminalInfoModal(true)}> View Details</Link>
        </Space>
      ),
    },
  ];
  
  return (
    <>
      <Modal 
        footer={null} 
        visible={showTerminalInfoModal} 
        onCancel={() => setShowTerminalInfoModal(false)}
        closeIcon={<img style={{marginRight: 20, marginTop: 20}} src={require("../../asset/close-circle.svg").default} />}
      >
        <ViewSelectedTerminalInfo />
      </Modal>
      <div style={{ margin: "3% 0" }}>
        <Filter />
      </div>

      <Table 
        columns={columns} 
        dataSource={terminalData?.data?? []} 
        loading={loadingTerminals} 
        pagination={{
          total: terminalData?.total?? 1,
          current: terminalData?.current_page,
          onChange: (page)  => {
            setPage(page);
          }
        }}
      />
    </>
  );
};

export default TerminalListing;

import api from "../../Lib/customAxios";
import {
  startAllAdmin,
  adminSuccess,
  adminFailure,
  activateAdmin,
  startPermission,
  permissionSuccess,
  permissonFailure,
  startAddPermission,
  addPermissionFailure,
} from "../Slices/admin";
import Toast from "../../../components/Toast/toast";

export const getAdmin = async (dispatch) => {
  dispatch(startAllAdmin());
  try {
    const res = await api.get("/account/all");
    if (res.data.code === 200 && res.data.status === "success") {
      return dispatch(adminSuccess(res.data.data));
    }
    if (res.data.code === 401) {
      localStorage.removeItem("TOKEN");
      window.location.href = "/";
    } else {
      dispatch(adminFailure());
    }
  } catch (error) {
    dispatch(adminFailure());
  }
};

export const toggleAdmin = async (dispatch, email, type, reason) => {
  try {
    const res = await api.post(`/account/${email}/${type}`, reason);
    if (res.data.code === 200 && res.data.status === "success") {
      Toast(res.data.message, "success");
      return dispatch(activateAdmin(email));
    }
    if (res.data.code === 401) {
      localStorage.removeItem("TOKEN");
      window.location.href = "/";
    } else {
      dispatch(adminFailure());
      Toast(res.data.message, "error");
    }
  } catch (error) {
    dispatch(adminFailure());
  }
};

export const getPermission = async (dispatch) => {
  dispatch(startPermission());
  try {
    const res = await api.get("/permissions");
    if (res.data.code === 200 && res.data.status === "success") {
      return dispatch(permissionSuccess(res.data.data));
    }
    if (res.data.code === 401) {
      localStorage.removeItem("TOKEN");
      window.location.href = "/";
    } else {
      dispatch(permissonFailure());
    }
  } catch (error) {
    dispatch(permissonFailure());
  }
};

export const AddPermission = async (dispatch, email, payload) => {
  dispatch(startAddPermission());
  try {
    const res = await api.post(`/account/${email}/permissions/assign`, payload);
    if (res.data.code === 200 && res.data.status === "success") {
      Toast(res.data.message, "success");
      return dispatch(addPermissionFailure());
    }
    if (res.data.code === 401) {
      localStorage.removeItem("TOKEN");
      window.location.href = "/";
    } else {
      dispatch(addPermissionFailure());
      Toast(res.data.message, "error");
    }
  } catch (error) {
    dispatch(addPermissionFailure());
  }
};

import { createSlice } from "@reduxjs/toolkit";

export const auditSlice = createSlice({
  name: "audit",
  initialState: {
    audit: [],
    loading: false,
    totalpage: 0,
    perpage: 0,
  },
  reducers: {
    startAudit: (state) => {
      state.loading = true;
    },
    fulfilledAudit: (state, { payload }) => {
      state.loading = false;
      state.audit = payload.data;
      state.totalpage = payload.total;
      state.perpage = payload.perpage;
    },
    errorAudit: (state) => {
      state.loading = false;
    },
  },
});

export const { startAudit, fulfilledAudit, errorAudit } = auditSlice.actions;

import api from "../../Lib/customAxios";
import Toast from "../../../components/Toast/toast";
import { fulfilledAgents } from "../Slices/kyc";
export const upgradeTier = async (id, payload) => {
  try {
    const res = await api.post(`/accounts/${id}/upgrade`, payload);
    if (res.data.code === 200 && res.data.status === "success") {
      Toast(res.data.message, "success");
    }
    if (res.data.code === 401) {
      localStorage.removeItem("TOKEN");
      window.location.href = "/";
    }
    if (res.data.code === 400) {
      Toast(res.data.message, "error");
    }
  } catch (error) {
    console.log(error.response);
  }
};

export const getSuperAgents = async (dispatch) => {
  try {
    const res = await api.get("/superagents");
    if (res.data.code === 200 && res.data.status === "success") {
      //Toast(res.data.message, "success");
      return dispatch(fulfilledAgents(res.data.data));
    }
    if (res.data.code === 401) {
      localStorage.removeItem("TOKEN");
      window.location.href = "/";
    }
    if (res.data.code === 400) {
      Toast(res.data.message, "error");
    }
  } catch (error) {
    
  }
};

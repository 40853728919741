import React from "react";
import classes from "./ServiceProviders.module.css";
import { useDispatch } from "react-redux";
import { addService } from "../Redux/Api/service";

const data = [
  "Airtime",
  "Internet",
  "CableTV",
  "Power",
  "Universal Reacharge",
  "9PSB",
  "Money Transfer",
  "Airpay",
  "Examination",
  "Bulk Recharge",
];
const data2 = ["Active", "Inactive"];

const EditProvider = ({ setShow, dataSource }) => {
  const dispatch = useDispatch();
  const [provider, setProvider] = React.useState(dataSource.type);
  const [category, setCategory] = React.useState(data[0]);
  const [api, setApi] = React.useState(dataSource.api_key ?? "N/A");
  const [file, setFile] = React.useState("");
  const [status, setStatus] = React.useState(dataSource.status);

  const handleSubmit = (e) => {
    e.preventDefault();
    addService(dispatch, {
      api_key: api,
      status,
      category_id: category,
      logo: file,
      name: provider,
    });
    setProvider("");
    setCategory(data[0]);
    setApi("");
    setFile("");
    setShow(false);
  };
  return (
    <form onSubmit={handleSubmit}>
      <p className={classes.heading}>Edit Service Provider</p>
      <div className={classes.container}>
        <input
          id="provider"
          type="text"
          value={provider}
          onChange={(e) => {
            setProvider(e.target.value);
          }}
          placeholder="Service Provider"
          className={classes.containerInput}
          required
        />

        <label htmlFor="provider" className={classes.containerLabel}>
          Edit Service Provider
        </label>
      </div>

      <div className={classes.selectContainer}>
        <label htmlFor="roles">Service Category</label>
        <select
          required
          id="roles"
          className={classes.selectItem}
          onInput={(e) => {
            setCategory(e.target.value);
          }}
        >
          {data.map((e, i) => (
            <option key={i} value={e}>
              {e}
            </option>
          ))}
        </select>
      </div>
      <div className={classes.container}>
        <p className={classes.fileLabel}>Upload Logo</p>
        <label className={classes.fileLabel2} htmlFor="upload">
          {file.name ?? "Select .PNG or .JPG Files"}
        </label>
        <input
          id="upload"
          type="file"
          className={classes.files}
          onChange={(e) => setFile(e.target.files[0])}
        />
      </div>
      <div className={classes.container}>
        <input
          id="api"
          type="text"
          value={api}
          onChange={(e) => {
            setApi(e.target.value);
          }}
          placeholder="Api Key"
          className={classes.containerInput}
          required
        />

        <label htmlFor="api" className={classes.containerLabel}>
          Api Key
        </label>
      </div>
      <div className={classes.selectContainer}>
        <label htmlFor="status">Change status</label>
        <select
          required
          id="status"
          className={classes.selectItem}
          onInput={(e) => {
            setStatus(e.target.value);
          }}
        >
          {data2.map((e, i) => (
            <option key={i} value={e.toLowerCase()}>
              {e}
            </option>
          ))}
        </select>
      </div>
      <div className={classes.adminButtonContainer}>
        <button
          className={classes.addBtn}
          onClick={() => {
            setShow(false);
          }}
          type="button"
        >
          Cancel
        </button>
        <button type="submit" className={classes.cancelBtn}>
          Save Changes
        </button>
      </div>
    </form>
  );
};

export default EditProvider;

import React, { useState } from "react";
import TextInput from "../../components/text-input/text-input";
import Button from "../../components/button/button";
import "./css/forgot-password.css";
import Card from "../../components/card/card";
import { Link } from "react-router-dom";
import Toast from "../../components/Toast/toast";
import { useHistory } from "react-router";
import api from "../Lib/customAxios";

interface Props {
  returningUser?: boolean;
}

const ForgotPassword: React.FC<Props> = ({ returningUser }) => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const handleSubmit = async () => {
    setLoading(true);
    const res = await api.post(
      `${process.env.REACT_APP_BASE_URL}/auth/password/retrieval`,
      { login_id: email }
    );
    console.log(res);
    if (res?.data && res.data?.code === 200) {
      localStorage.setItem("reset-password-id", res.data.data.id);
      Toast(res.data.message, "success");
      history.push("/reset-password");
    } else {
      setLoading(false);
      Toast(res?.data.message, "error");
    }
    setLoading(false);
  };

  return (
    <>
      <div className="box-management box-bg">
        <div className="logo-area-login">
          <img
            src={require("../../asset/airvendlogo 1.svg").default}
            alt=""
            className="login-logo"
          />
        </div>

        <div style={{ height: 36 }} />

        <Card className="box-card">
          <div className="central-area">
            <div className="key-area">
              <img
                src={require("../../asset/key1.svg").default}
                alt="key"
                className="key-icon"
              />
            </div>
            <div className="text-info-area-for">
              <p>
                We would send an email to you to help you reset and get back
                into your account in no time
              </p>
            </div>
            <div className="email-sub-area">
              <TextInput
                type="text"
                labelName="Email Address"
                onInput={(e: any) => setEmail(e.target.value)}
              />

              <br />
            </div>
            <div className="submit-button">
              <Button
                btn_name="Continue"
                color="btn-green"
                onClick={handleSubmit}
                loading={loading}
              />
            </div>
          </div>
        </Card>
        <div className="create">
          <span>Recall your Password?</span>
          <Link to="/">Login here</Link>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;

import { createSlice } from "@reduxjs/toolkit";

export const rolesSlice = createSlice({
  name: "rolesSlice",
  initialState: {
    loading: false,
    roles: [],
    addRolesLoading: false,
  },
  reducers: {
    startRolesFetch: (state) => {
      state.loading = true;
    },
    rolesFetchSuccess: (state, { payload }) => {
      state.roles = payload;
      state.loading = false;
    },
    addRoleStart: (state) => {
      state.addRolesLoading = true;
    },
    addRoleSuccess: (state) => {
      state.addRolesLoading = false;
    },
    addRoleFailure: (state) => {
      state.addRolesLoading = false;
    },
    rolesFetchError: (state) => {
      state.loading = false;
    },
  },
});

export const {
  startRolesFetch,
  rolesFetchSuccess,
  rolesFetchError,
  addRoleStart,
  addRoleFailure,
  addRoleSuccess,
} = rolesSlice.actions;

import React from "react";
import MainLayout from "./MainLayout/MainLayout";
import Login from "./Login/Login";
import { Redirect, Route, Switch } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { loginSuccess, logout } from "./Redux/Slices/auth";
import ForgotPassword from "./ForgetPassword/ForgotPassword";
import ResetPassword from "./ResetPassword/ResetPassword";

const App = () => {
  const dispatch = useDispatch();
  const token = localStorage.getItem("TOKEN");

  if (token) {
    dispatch(loginSuccess());
  }

  const loggedIn = useSelector((state) => state.auth.authStatus);

  return (
    <>
      {loggedIn ? (
        <Switch>
          <MainLayout />
        </Switch>
      ) : (
        <Switch>
          <Route path="/" exact>
            <Login />
          </Route>
          <Route path="/forgot-password" exact>
            <ForgotPassword />
          </Route>
          <Route path="/reset-password" exact>
            <ResetPassword />
          </Route>
          <Route path="*" exact>
            <Redirect to="/login" />
          </Route>
        </Switch>
      )}
    </>
  );
};

export default App;

import React, { useEffect } from "react";
import classes from "./add_terminal.module.css";



interface AddMerchantTerminalProps {
  formData: any,
  setFormData: any,
  isLastStep: boolean
}

const formInitState = {
  merchant_name: '', merchant_id: '', terminal_id: ''
};

const banks = [
  'Providus',
  'GTBank'
];


const AddMerchantTerminal = ({ formData, setFormData, isLastStep }: AddMerchantTerminalProps) => {

  const handleFormInput = (key: string, value: string) => {
    setFormData({ ...formData, [key]: value });
  }

  useEffect(() => {
    setFormData({ ...formData, ...formInitState });
  }, []);

  return (
    <>
      {
        !isLastStep && <>
          <div className={classes.container}>
            <input
              id="merchant_name"
              type="text"
              value={formData?.merchant_name}
              onChange={(e) => {
                handleFormInput('merchant_name', e.target.value);
              }}
              placeholder="Merchant Name"
              className={classes.containerInput}
              required
            />

            <label htmlFor="merchant_name" className={classes.containerLabel}>
              Merchant Name
            </label>
          </div>

          <div className={classes.flex}>

            <div className={classes.container}>
              <input
                type="text"
                value={formData?.merchant_id}
                onChange={(e) => handleFormInput('merchant_id', e.target.value)}
                placeholder="Merchant ID"
                id="merchant_id"
                className={classes.containerInput}
                required
              />

              <label htmlFor="merchant_id" className={classes.containerLabel}>
                Merchant ID
              </label>
            </div>

            <div className={classes.container}>
              <input
                type="text"
                value={formData?.terminal_id}
                onChange={(e) => handleFormInput('terminal_id', e.target.value)}
                placeholder="Terminal ID"
                id="terminal_id"
                className={classes.containerInput}
                required
              />

              <label htmlFor="terminal_id" className={classes.containerLabel}>
                Terminal ID
              </label>
            </div>
          </div>

          <div className={classes.flex}>

            <div className={classes.container}>
              <input
                type="text"
                value={formData?.account_number}
                onChange={(e) => handleFormInput('account_number', e.target.value)}
                placeholder="Account Number"
                id="account_number"
                className={classes.containerInput}
                required
              />

              <label htmlFor="account_number" className={classes.containerLabel}>
                Account Number
              </label>
            </div>

            <div className={classes.container}>
              <select
                required
                id="bank"
                className={classes.containerInput}
                onInput={(e) => {
                  handleFormInput('bank', e.target.value);
                }}
              >
                {banks.map((e, i) => (
                  <option key={i} value={e}>
                    {e}
                  </option>
                ))}
              </select>
              <label htmlFor="bank" className={classes.containerLabel}>
                Bank
              </label>
            </div>
          </div>

        </>
      }

      {
        isLastStep && <>
          <div className={classes.flex}>
            <div className={classes.container}>
              <input
                type="text"
                value={formData?.email}
                onChange={(e) => handleFormInput('email', e.target.value)}
                placeholder="email"
                id="email"
                className={classes.containerInput}
                required
              />

              <label htmlFor="email" className={classes.containerLabel}>
                Email
              </label>
            </div>

            <div className={classes.container}>
              <input
                type="text"
                value={formData?.phone_number}
                onChange={(e) => handleFormInput('phone_number', e.target.value)}
                placeholder="phone_number"
                id="phone_number"
                className={classes.containerInput}
                required
              />

              <label htmlFor="phone_number" className={classes.containerLabel}>
                Phone Number
              </label>
            </div>
          </div>

          <div className={classes.container}>
            <input
              type="text"
              value={formData?.country}
              onChange={(e) => handleFormInput('country', e.target.value)}
              placeholder="Country"
              id="country"
              className={classes.containerInput}
              required
            />

            <label htmlFor="country" className={classes.containerLabel}>
              Country
            </label>
          </div>

          <div className={classes.flex}>
            <div className={classes.container}>
              <input
                type="text"
                value={formData?.city}
                onChange={(e) => handleFormInput('city', e.target.value)}
                placeholder="City"
                id="city"
                className={classes.containerInput}
                required
              />

              <label htmlFor="city" className={classes.containerLabel}>
                City
              </label>
            </div>

            <div className={classes.container}>
              <input
                type="text"
                value={formData?.address}
                onChange={(e) => handleFormInput('address', e.target.value)}
                placeholder="Address"
                id="address"
                className={classes.containerInput}
                required
              />

              <label htmlFor="address" className={classes.containerLabel}>
                Address
              </label>
            </div>
          </div>

          <div className={classes.flex}>
            <div className={classes.container}>
              <input
                type="text"
                value={formData?.slipper_header}
                onChange={(e) => handleFormInput('slipper_header', e.target.value)}
                placeholder="Slipper Header"
                id="slipper_header"
                className={classes.containerInput}
                required
              />

              <label htmlFor="slipper_header" className={classes.containerLabel}>
                Slipper Header
              </label>
            </div>

            <div className={classes.container}>
              <input
                type="text"
                value={formData?.slipper_footer}
                onChange={(e) => handleFormInput('slipper_footer', e.target.value)}
                placeholder="Slipper Footer"
                id="slipper_footer"
                className={classes.containerInput}
                required
              />

              <label htmlFor="slipper_footer" className={classes.containerLabel}>
                Slipper Footer
              </label>
            </div>
          </div>
        </>
      }

    </>
  );
};

export default AddMerchantTerminal;

import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    loading: false,
    users: [],
    totalpage: 0,
    perpage: 0,
  },
  reducers: {
    fetchStart: (state) => {
      state.loading = true;
    },
    fetchFulfilled: (state, { payload }) => {
      state.loading = false;
      state.users = payload.data;
      state.totalpage = payload.total;
      state.perpage = payload.perpage;
    },
    fetchError: (state) => {
      state.loading = false;
    },
    activateUsers: (state, { payload }) => {
      state.users.map((e, i) => {
        if (e.account_id === payload) {
          if (e.status === "active") {
            return (e.status = "inactive");
          } else {
            return (e.status = "active");
          }
        }
        return state.users;
      });
    },
  },
});
export const { fetchError, fetchStart, fetchFulfilled, activateUsers } =
  userSlice.actions;

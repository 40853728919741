import { errorAudit, startAudit, fulfilledAudit } from "../Slices/audit";

import api from "../../Lib/customAxios";

export const getAudit = async (dispatch, page) => {
  dispatch(startAudit());
  try {
    const res = await api.get(`/audit-logs?page=${page}`);
    if (res.data.code === 200 && res.data.status === "success") {
      return dispatch(
        fulfilledAudit({
          data: res.data.data.data,
          total: res.data.data.total,
          perpage: res.data.data.per_page,
        })
      );
    }
    if (res.data.code === 401) {
      localStorage.removeItem("TOKEN");
      window.location.href = "/";
    } else {
      dispatch(errorAudit());
    }
  } catch (error) {
    dispatch(errorAudit());
  }
};

import React from "react";
import Card from "../../card/card";
import btnsearch from "../../../../asset/bill-payments/btnSearch.svg";
import preview from "../../../../asset/bill-payments/preview.svg";
import classes from "../kycnavs.module.css";
import { Link } from "react-router-dom";
function BusinessDetails({ data }: any) {
  console.log("loading business detail");
  const uploadurl = data?.business_doc?.upload_url;
  return (
    <Card className="box-card-details">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className="details-main">
          <div className="space-rule">
            <div className="details-title">Business Name</div>
            <div className="details-text">
              {data?.business_doc?.business_name}
            </div>
          </div>
          <div className="space-rule">
            <div className="details-title">Business Number</div>
            <div className="details-text">
              {data?.business_doc?.business_number}
            </div>
          </div>
          <div className="space-rule">
            <div className="details-title">COMPANY ADDRESS</div>
            <div className="details-text">
              {data?.business_doc?.company_address}
            </div>
          </div>
          <div className="space-rule">
            <div className="details-title">ROLE</div>
            <div className="details-text">{data?.business_doc?.role}</div>
          </div>
          <div className="space-rule">
            <div className="details-title">TRANSACTION VOLUME</div>
            <div className="details-text">N/A</div>
          </div>
        </div>

        <img
          className="details-img"
          width="78"
          height="78"
          src={data.user?.photo_url || preview}
          alt="img"
        />
      </div>

      <br />

      {uploadurl ? (
        <a
          href={uploadurl}
          className={classes.btn}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={btnsearch} alt="upload search" />
          View CAC Document
        </a>
      ) : (
        <button className={classes.btn} disabled>
          <img src={btnsearch} alt="upload search" />
          <span>No ID Document</span>
        </button>
      )}
    </Card>
  );
}

export default React.memo(BusinessDetails);

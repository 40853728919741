import axios from "axios";

const customAxios = axios.create();

customAxios.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("TOKEN");
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    config.headers["platform"] = "agent-web";
    config.baseURL = `${process.env.REACT_APP_BASE_URL}`;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
customAxios.interceptors.response.use(
  (response) => response,

  (error) => {
    if (error.response.data.code === 401) {
      localStorage.removeItem("TOKEN");
      window.location.href = "/";
    }
  }
);

export default customAxios;

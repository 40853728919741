import React from "react";
import Ellipse from "../../../asset/bill-payments/Ellipse.svg";
import classes from "./loader.module.css";
const Loader = () => {
  return (
    <div className={classes.main}>
      <img src={Ellipse} alt="" className={classes.loading} />
    </div>
  );
};

export default Loader;

import { createSlice } from "@reduxjs/toolkit";

export const adminSlice = createSlice({
  name: "adminSlice",
  initialState: {
    loadingAllAdmin: false,
    allAdmin: [],
    allPermissions: [],
    loadingPermissions: false,
    addPermLoading: false,
  },
  reducers: {
    startAllAdmin: (state) => {
      state.loadingAllAdmin = true;
    },
    adminSuccess: (state, { payload }) => {
      state.allAdmin = payload;
      state.loadingAllAdmin = false;
    },
    activateAdmin: (state, { payload }) => {
      state.allAdmin.map((e, i) => {
        if (e.email === payload) {
          if (e.status === "active") {
            return (e.status = "inactive");
          } else {
            return (e.status = "active");
          }
        }
        return state.allAdmin;
      });
    },
    adminFailure: (state) => {
      state.loadingAllAdmin = false;
    },
    startPermission: (state) => {
      state.loadingPermissions = true;
    },
    permissionSuccess: (state, { payload }) => {
      state.allPermissions = payload;
      state.loadingPermissions = false;
    },
    permissonFailure: (state) => {
      state.loadingPermissions = false;
    },
    startAddPermission: (state) => {
      state.addPermLoading = true;
    },
    addPermissionFailure: (state) => {
      state.addPermLoading = false;
    },
  },
});

export const {
  startAllAdmin,
  adminSuccess,
  adminFailure,
  activateAdmin,
  startPermission,
  permissionSuccess,
  permissonFailure,
  startAddPermission,
  addPermissionFailure,
} = adminSlice.actions;

import React from "react";
import Button from "../../components/button/button";
import Card from "../../components/card/card";
import Dropdown from "../../components/dropdown/dropdown";
import { Input } from "antd";
import TextInput from "../../components/text-input/text-input";
import PageHeader from "../components/PageHeader/PageHeader";

function Notifications() {
  const { TextArea } = Input;

  return (
    <div>
      <PageHeader heading="Send Push Notifications" />
      <div className="box-core">
        <Card className="input-card">
          <form action="/">
            <Dropdown
              label="Select Tier Level"
              valueKey={"id"}
              displayKey={"name"}
              data={[
                { id: 2, name: "Tier 1" },
                { id: 3, name: "Tier 2" },
                { id: 4, name: "Tier 3" },
              ]}
              onInput={() => {}}
            />
            <div style={{ height: 30 }} />
            <TextInput labelName="Notification Title" onInput={() => {}} />
            <div style={{ height: 30 }} />
            <TextArea
              className="custom-input"
              rows={5}
              placeholder="Description"
            />
            <div style={{ height: 30 }} />
            <Button
              id="myBtn"
              btn_name="Send Notifications"
              color="btn-green"
              onClick={() => {}}
            />
          </form>
        </Card>
      </div>
    </div>
  );
}

export default Notifications;

import React, { useEffect, useState } from "react";
import classes from "./Users.module.css";
import Filter from "../components/Filter/Filter";
import { Space, Table } from "antd";
import { Link } from "react-router-dom";
import { getUsers, toggleUser } from "../Redux/Api/users";
import { useDispatch, useSelector } from "react-redux";
import PageHeader from "../../components/PageHeader/PageHeader";
import { useHistory } from "react-router-dom";
import styles from "../Admin/admin.module.css";
const Users = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [query, setQuery] = useState(false);
  const [page, setPage] = useState(1);

  const datas = useSelector((state) => state.user.users);
  const loading = useSelector((state) => state.user.loading);
  const totalPage = useSelector((state) => state.user.totalpage);
  const perpage = useSelector((state) => state.user.perpage);

  //const keys = ["fullname", "phonenumber", "email"];
  // const search = (data) => {
  //   return data.filter((item) =>
  //     keys.some((key) => item[key].toLowerCase().includes(query))
  //   );
  // };

  useEffect(() => {
    history.push(`/users?page=${page}`);
    getUsers(dispatch, page);
  }, [dispatch, page]);

  const columns = [
    {
      title: "Full Name",
      dataIndex: "fullname",
      key: "fullname",
      sorter: (a, b) => a.fullname - b.fullname,
    },
    {
      title: "Phone Number",
      dataIndex: "phonenumber",
      key: "phonenumber",
    },
    {
      title: "Email Address",
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => a.email - b.email,
    },
    {
      title: "Tier Level",
      dataIndex: "tierlevel",
      key: "tierlevel",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <Space size="middle">
          {status === "active" ? (
            <span className={styles.active}>{status}</span>
          ) : (
            <span className={styles.inactive}>{status}</span>
          )}
        </Space>
      ),
    },
    {
      title: "",
      key: "action",
      render: (key) => (
        <Space size="middle">
          {key.status === "active" ? (
            <button
              onClick={() =>
                toggleUser(dispatch, key.key, "deactivate", {
                  reason: "Noticed fraudulent activities",
                })
              }
              className={styles.deactiveBtn}
            >
              Deactivate
            </button>
          ) : (
            <button
              onClick={() =>
                toggleUser(dispatch, key.key, "activate", {
                  reason: "A new Customer",
                })
              }
              className={styles.activeBtn}
            >
              Activate
            </button>
          )}
        </Space>
      ),
    },
    {
      title: "",
      key: "action",
      render: (key) => (
        <Space size="middle" className={classes.review}>
          <Link to={`/users/${key.key}`}> View Details</Link>
        </Space>
      ),
    },
  ];
  const userLoading = useSelector((state) => state.userPersonal.loading);
  const userData = useSelector((state) => state.userPersonal.userData);

  const data = datas.map((e) => ({
    key: e.account_id,
    fullname: e.user_data?.name || "No name",
    phonenumber: e?.phone_number,
    email: e.email,
    tierlevel: `Tier ${e.user_type}`,
    status: e.status,
  }));
  const userData2 = [
    {
      key: userData?.account_id,
      fullname: userData?.user_data?.name || "No name",
      phonenumber: userData?.phone_number,
      email: userData.email,
      tierlevel: `Tier ${userData.user_type}`,
      status: userData?.status,
    },
  ];
  console.log(userData);
  return (
    <>
      <PageHeader heading="All Users" />
      <Filter setQuery={setQuery} data={data} reset="users" />
      {query ? (
        <Table columns={columns} dataSource={userData2} loading={userLoading} />
      ) : (
        <Table
          columns={columns}
          dataSource={data}
          loading={loading}
          pagination={{
            pageSize: perpage,
            total: totalPage,
            onChange: (page) => {
              setPage(page);
            },
          }}
        />
      )}
    </>
  );
};

export default Users;

import { createSlice } from "@reduxjs/toolkit";

export const terminalSlice = createSlice({
  name: "terminalSlice",
  initialState: {
    loading: false,
    posEnv: [],
    terminals: [],
    assignedTerminals: [],
    terminalInfo: {},
  },
  reducers: {
    startPosEnv: (state) => {
      state.loading = true;
    },
    posEnvSuccess: (state, { payload }) => {
      state.posEnv = payload;
      state.loading = false;
    },
    addTerminalStart: (state) => {
      state.loading = true;
    },
    addTerminalSuccess: (state) => {
      state.loading = false;
    },
    addTerminalFailure: (state) => {
      state.loading = false;
    },
    posEnvError: (state) => {
      state.loading = false;
    },
    fetchAssignedTerminalsSuccess: (state, { payload }) => {
      state.assignedTerminals = payload;
      state.loading = false;
    },
    fetchSingleTerminalSuccess: (state, { payload }) => {
      state.terminalInfo = payload;
      state.loading = false;
    },
    startTerminalFetch: (state) => {
      state.loading = true;
    },
    fetchTerminalSuccess: (state, { payload }) => {
      state.terminals = payload;
      state.loading = false;
    },
    fetchTerminalError: (state) => {
      state.loading = false;
    }
  },
});

export const {
  startPosEnv,
  posEnvError,
  posEnvSuccess,
  addTerminalStart,
  addTerminalSuccess,
  addTerminalFailure,
  startTerminalFetch,
  fetchTerminalSuccess,
  fetchTerminalError,
  fetchAssignedTerminalsSuccess
} = terminalSlice.actions;

import React from "react";
import funnel from "../../../asset/bill-payments/funnel.svg";
import search from "../../../asset/bill-payments/Search.svg";
import classes from "./filter.module.css";
import { CSVLink } from "react-csv";
import { useDispatch } from "react-redux";
import { getKycPersonal } from "../../Redux/Api/kycPersonal";
import { getUserPersonal } from "../../Redux/Api/userPersonal";
import { Link } from "react-router-dom";
interface Props {
  service?: string;
  setQuery?: any;
  data?: any;
  showModal?: any;
  showAdd?: any;
  type?: string;
}

const Filter = ({
  service,
  setQuery,
  data,
  showModal,
  showAdd,
  type,
}: Props) => {
  const dispatch = useDispatch();
  const [text, setText] = React.useState("");

  const handleSearch = (e: any) => {
    e.preventDefault();
    if (type === "kyc") {
      getKycPersonal(dispatch, text);
    } else {
      getUserPersonal(dispatch, text);
    }
    setQuery(true);
  };
  const handleReset = (e: any) => {
    e.preventDefault();
    setText("");
    setQuery(false);
  };
  return (
    <div className={classes.container}>
      <button className={classes.Buttonfilter}>
        <img src={funnel} alt="search" placeholder="funnel-search" />
        <span>Select Filter</span>
      </button>
      <form className={classes.searchContainer}>
        <button className={classes.searchIcon} onClick={handleSearch}>
          <img src={search} alt="search" />
        </button>
        <input
          type="text"
          value={text}
          placeholder="Search..."
          className={classes.searchInput}
          onChange={(e) => {
            setText(e.target.value);
          }}
        />
        {setQuery && (
          <button className={classes.buttonFilter} onClick={handleReset}>
            Reset
          </button>
        )}
      </form>

      {service ? (
        <div className={classes.buttonContainer}>
          <button
            className={classes.filterButton}
            onClick={() => {
              showModal(true);
              showAdd(true);
            }}
          >
            {service}
          </button>
        </div>
      ) : (
        <div className={classes.buttonContainer}>
          <button>
            {data? <CSVLink data={data}>Export CSV</CSVLink> : <Link to='#'>Export CSV</Link>}
          </button>
        </div>
      )}
    </div>
  );
};

export default Filter;

import { createSlice } from "@reduxjs/toolkit";

export const userPersonal = createSlice({
  name: "userPersonal",
  initialState: { userData: [], loading: false },
  reducers: {
    startFetch: (state) => {
      state.loading = true;
    },
    fulfilledFetch: (state, { payload }) => {
      state.loading = false;
      state.userData = payload;
    },
    addMain: (state, { payload }) => {
      state.userData.wallet.main_balance += payload;
    },
    addCommission: (state, { payload }) => {
      state.userData.wallet.commission_balance += payload;
    },
    addIncentive: (state, { payload }) => {
      state.userData.wallet.incentive_balance += payload;
    },
    addAirPay: (state, { payload }) => {
      state.userData.wallet.airpay_balance += payload;
    },
    errorFetch: (state) => {
      state.loading = false;
    },
  },
});

export const {
  startFetch,
  fulfilledFetch,
  errorFetch,
  addAirPay,
  addCommission,
  addIncentive,
  addMain,
} = userPersonal.actions;

import React from "react";
import classes from "./audit.module.css";
import Card from "../card/card";
import preview from "../../../asset/bill-payments/preview.svg";
import btnsearch from "../../../asset/bill-payments/btnSearch.svg";

function UserDetails({ data }: any) {
  console.log(data);
  const phone = `0${data?.phone_number.slice(3, -1)}`;
  const uploadurl = data?.upload_url;

  return (
    <div className={`box-core ${classes.boxCore}`}>
      <Card className="box-card-details">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div className="details-main">
            <div className="space-rule">
              <div className="details-title">Full Name</div>
              <div className="details-text">
                {data?.user_data?.name?.toUpperCase()}
              </div>
            </div>
            <div className="space-rule">
              <div className="details-title">PHONE NUMBER</div>
              <div className="details-text">{phone}</div>
            </div>
            <div className="space-rule">
              <div className="details-title">EMAIL ADDRESS</div>
              <div className="details-text">{data?.email}</div>
            </div>
            <div className="space-rule">
              <div className="details-title">MERCHANT ID</div>
              <div className="details-text">
                {data?.is_merchant ?? "No Merchant ID"}
              </div>
            </div>
            <div className="space-rule">
              <div className="details-title">RESIDENTIAL ADDRESS</div>
              <div className="details-text">{data?.user_data?.address}</div>
            </div>
          </div>

          <img
            className="details-img"
            width="78"
            height="78"
            src={data?.photo_url || preview}
            alt="img"
          />
        </div>

        <br />

        {uploadurl ? (
          <a
            href={uploadurl}
            className={classes.btn}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={btnsearch} alt="upload search" />
            View CAC Document
          </a>
        ) : (
          <button className={classes.btn} disabled>
            <img src={btnsearch} alt="upload search" />
            <span>No ID Document</span>
          </button>
        )}
      </Card>
    </div>
  );
}

export default UserDetails;

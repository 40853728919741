import React from "react";
import Card from "../components/card/card";
import classes from "./admin.module.css";
import { useSelector, useDispatch } from "react-redux";
import { addRole, getRoles } from "../Redux/Api/roles";

const InviteAdmin = () => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    getRoles(dispatch);
  }, [dispatch]);

  const data = useSelector((state) => state.roles.roles).map((e) => e.name);
  const loading = useSelector((state) => state.roles.loading);
  const addRoleloading = useSelector((state) => state.roles.addRolesLoading);

  const [role, setRole] = React.useState(data[0]);
  const [name, setname] = React.useState("");
  const [email, setEmail] = React.useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    addRole(dispatch, { name, email, role });

    setEmail("");
    setname("");
    setRole(data[0]);
  };

  return (
    <form onSubmit={handleSubmit} style={{ marginTop: 30 }}>
      <Card>
        <div>
          <p className={classes.header}>
            To invite a new admin, simply input the airvend email address and
            admin role.
          </p>
          <div className={classes.container}>
            <input
              id="name"
              type="text"
              value={name}
              onChange={(e) => {
                setname(e.target.value);
              }}
              placeholder="Full Name"
              className={classes.containerInput}
              required
            />

            <label htmlFor="name" className={classes.containerLabel}>
              Full Name
            </label>
          </div>
          <div className={classes.container}>
            <input
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email Address"
              id="email"
              className={classes.containerInput}
              required
            />

            <label htmlFor="email" className={classes.containerLabel}>
              Email Address
            </label>
          </div>
          <div className={classes.selectContainer}>
            <label htmlFor="roles">Select Roles</label>
            <select
              required
              id="roles"
              className={classes.selectItem}
              onInput={(e) => {
                setRole(e.target.value);
              }}
            >
              {loading ? (
                <option>Loading Roles ... </option>
              ) : (
                data.map((e, i) => (
                  <option key={i} value={e}>
                    {e}
                  </option>
                ))
              )}
            </select>
          </div>
          <div className={classes.btnContainer}>
            <button type="submit" disabled={addRoleloading}>
              {addRoleloading ? "Adding New Admin" : "Add New Admin"}
            </button>
          </div>
        </div>
      </Card>
    </form>
  );
};

export default InviteAdmin;

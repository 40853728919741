import React from "react";
import classes from "./dropdown.module.css";

interface Props {
  data?: any[];
  label?: string;
  valueKey?: string;
  displayKey?: string;
  onInput: (e: any) => void;
  disabled?: boolean;
  selected?: string;
  className?: string;
}

export default function Dropdown(props: Props) {
  const {
    data,
    label,
    valueKey,
    displayKey,
    onInput,
    disabled,
    selected,
    className,
  } = props;
  const _valueKey = valueKey || "";
  const _displayKey = displayKey || "";
  // e[_displayKey] == selected
  return (
    <select
      onInput={onInput}
      className={`${classes.dropdown} ${className} ${
        disabled ? `${classes.dropdownDisabled}` : ""
      }`}
    >
      {label && <option>{label}</option>}
      {data?.map((e: any, i: any) => (
        <option defaultValue={selected} value={data[i][_valueKey]} key={i}>
          {e[_displayKey]}
        </option>
      ))}
    </select>
  );
}

import { createSlice } from "@reduxjs/toolkit";

export const transTypeSlice = createSlice({
  name: "transType",
  initialState: {
    transType: [],
    loading: false,
  },
  reducers: {
    startType: (state) => {
      state.loading = true;
    },
    fulfilledType: (state, { payload }) => {
      state.loading = false;
      state.transType = payload;
    },
    errorType: (state) => {
      state.loading = false;
    },
  },
});

export const { startType, fulfilledType, errorType } = transTypeSlice.actions;

import {
  startService,
  fulfilledService,
  errorService,
  startAddService,
  errorAddService,
} from "../Slices/service";
import api from "../../Lib/customAxios";
import Toast from "../../../components/Toast/toast";

export const getService = async (dispatch) => {
  dispatch(startService());
  try {
    const res = await api.get("/providers");
    if (res.data.code === 200 && res.data.status === "success") {
      return dispatch(fulfilledService(res.data.data));
    }
    if (res.data.code === 401) {
      localStorage.removeItem("TOKEN");
      window.location.href = "/";
    } else {
      dispatch(errorService());
    }
  } catch (error) {
    dispatch(errorService());
  }
};

export const addService = async (dispatch, payload) => {
  dispatch(startAddService());
  try {
    const res = await api.post("/providers/add", payload);
    if (res.data.code === 200 && res.data.status === "success") {
      return Toast(res.data.message, "success");
    }
    if (res.data.code === 401) {
      localStorage.removeItem("TOKEN");
      window.location.href = "/";
    } else {
      dispatch(errorAddService());
      return Toast(res.data.message, "error");
    }
  } catch (error) {
    dispatch(errorAddService());
  }
};

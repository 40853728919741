import React, { useState } from "react";
import TextInput from "../../components/text-input/text-input";
import Button from "../../components/button/button";
import "./css/reset-password.css";
import Card from "../../components/card/card";
import axios from "axios";
import Toast from "../../components/Toast/toast";
import { useHistory } from "react-router";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [password_confirmation, setPasswordConfirm] = useState("");
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const history = useHistory();

  const handleSubmit = async () => {
    setLoading(true);
    //const id = localStorage.getItem("reset-password-id");

    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/auth/password/reset`,
      {
        otp,
        password,
        password_confirmation,
      }
    );

    if (res.data && res.data.code === 200) {
      Toast(res.data.message, "success");
      history.push("/login");
    } else {
      Toast(res.data.message, "error");
    }
    setLoading(false);
  };

  return (
    <>
      <div className="box-management box-bg">
        <div className="logo-area-login">
          <img
            src={require("../../asset/airvendlogo 1.svg").default}
            alt=""
            className="login-logo"
          />
        </div>

        <div style={{ height: 36 }} />

        <Card className="box-card">
          <div className="central-area-reset">
            <div className="key-area">
              <img
                src={require("../../asset/key1.svg").default}
                alt="key"
                className="key-icon"
              />
            </div>
            <div className="text-info-area-for">
              <p>
                Please fill in the information below with a valid password to
                continue and check your mail for OTP
              </p>
            </div>
            <div className="email-sub-area">
              <TextInput
                type="text"
                labelName="OTP"
                onInput={(e: any) => setOtp(e.target.value)}
              />
            </div>
            <div className="new-pass-area-reset">
              <TextInput
                type="password"
                labelName="New Password"
                onInput={(e: any) => setPassword(e.target.value)}
              />
              <TextInput
                type="password"
                labelName="Confirm Password"
                onInput={(e: any) => setPasswordConfirm(e.target.value)}
              />
            </div>
            <div className="submit-button">
              <Button
                btn_name="Update Account"
                color="btn-green"
                loading={loading}
                onClick={handleSubmit}
              />
            </div>
          </div>
        </Card>
      </div>
    </>
  );
};

export default ResetPassword;

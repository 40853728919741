import {
  startDash,
  dashDataFulfilled,
  dashGraphFulfilled,
  dashFailed,
} from "../Slices/dashboard";
import api from "../../Lib/customAxios";

export const getDashBoard = async (dispatch) => {
  dispatch(startDash());
  try {
    const resGraph = await api.get("/balances");
    const resData = await api.get("/sales/2005");
    if (resData.data.code === 200 && resData.data.status === "success") {
      dispatch(dashDataFulfilled(resData.data.data));
    } else {
      dispatch(dashFailed());
    }
    if (resGraph.data.code === 200 && resGraph.data.status === "success") {
      dispatch(dashGraphFulfilled(resGraph.data.data));
    } else {
      dispatch(dashFailed());
    }
  } catch (error) {
    dispatch(dashFailed());
    console.log(error);
  }
};

import React from "react";
import classes from "./card.module.css";

interface Props {
  children?: any;
  className?: string;
}

export default function Card(props: Props) {
  return (
    <div className={`${classes.cardcustom} ${props.className}`}>
      {props.children}
    </div>
  );
}

import Toast from "../../../components/Toast/toast";
import {
  addAirPay,
  addCommission,
  addIncentive,
  addMain,
} from "../Slices/userPersonal";
import api from "../../Lib/customAxios";

export const getWallet = async (dispatch, payload) => {
  const { id, type, amount, wallet, reason } = payload;
  let walletAction;
  switch (wallet) {
    case "MAIN_WALLET":
      walletAction = addMain;
      break;
    case "COMMISSION_WALLET":
      walletAction = addCommission;
      break;
    case "INCENTIVE_WALLET":
      walletAction = addIncentive;
      break;
    case "AIRPAY_WALLET":
      walletAction = addAirPay;
      break;
    default:
      break;
  }

  try {
    const res = await api.post(`/wallet/${id}/${type}`, {
      amount,
      wallet_type: wallet,
      reason,
    });
    if (res.data.code === 200 && res.data.status === "success") {
      type === "credit"
        ? dispatch(walletAction(amount))
        : dispatch(walletAction(-amount));
      return Toast(res.data.message, "success");
    }
    if (res.data.code === 401) {
      localStorage.removeItem("TOKEN");
      window.location.href = "/";
    } else {
      Toast(res.data.message, "error");
    }
  } catch (error) {
    console.log(error.response);
  }
};

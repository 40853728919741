import React from "react";
import back from "../../asset/bill-payments/back-arrow.svg";
import { Link } from "react-router-dom";
import classes from "./PageHeader.module.css";

interface Props {
  heading?: string;
  to?: any;
}

const PageHeader = ({ heading, to }: Props) => {
  return (
    <div className={classes.heading}>
      {to && (
        <Link className={classes.goBackButton} to={to}>
          <img src={back} alt="hello" />
        </Link>
      )}
      <span>{heading}</span>
    </div>
  );
};

export default PageHeader;

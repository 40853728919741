const links = [
  "VIEW_USER",
  "AUDIT_LOG",
  "ADD_TERMINAL",
  "VIEW_KYC_SUBMISSIONS",
  "VIEW_PROVIDERS",
  "VIEW_ADMIN",
  "WALLET",
  "PUSH_NOTIFICATIONS",
];

export const viewUserRoute = (payload) =>
  payload?.includes(links[0]) ? true : false;

export const auditLogRoute = (payload) =>
  payload?.includes(links[1]) ? true : false;

export const addTerminalRoute = (payload) =>
  payload?.includes(links[2]) ? true : false;

export const viewKycRoute = (payload) =>
  payload?.includes(links[3]) ? true : false;

export const viewProvidersRoute = (payload) =>
  payload?.includes(links[4]) ? true : false;

export const viewAdminRoute = (payload) =>
  payload?.includes(links[5]) ? true : false;

export const viewWalletRoute = (payload) =>
  payload?.includes(links[6]) ? true : false;

export const viewNotificationsRoute = (payload) =>
  payload?.includes(links[7]) ? true : false;

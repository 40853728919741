import { createSlice } from "@reduxjs/toolkit";

export const kycPersonal = createSlice({
  name: "kycPersonal",
  initialState: {
    kycData: [],
    loading: false,
  },
  reducers: {
    startfetch: (state) => {
      state.loading = true;
    },
    fulfilledfetch: (state, { payload }) => {
      state.loading = false;
      state.kycData = payload;
    },
    errorfetch: (state) => {
      state.loading = false;
    },
  },
});

export const { startfetch, fulfilledfetch, errorfetch } = kycPersonal.actions;

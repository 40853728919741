import { errorfetch, fulfilledfetch, startfetch } from "../Slices/kycPersonal";
import api from "../../Lib/customAxios";

export const getKycPersonal = async (dispatch, id) => {
  dispatch(startfetch());
  try {
    const res = await api.get(`/accounts/${id}/kyc`);
    if (res.data.code === 200 && res.data.status === "success") {
      dispatch(fulfilledfetch(res.data.data));
    }
    if (res.data.code === 401) {
      localStorage.removeItem("TOKEN");
      window.location.href = "/";
    } else {
      dispatch(errorfetch());
    }
  } catch (error) {
    dispatch(errorfetch());
    console.log(error);
  }
};

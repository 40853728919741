import { CloseOutlined } from '@ant-design/icons';
import { Alert } from 'antd';
import React, { useState } from 'react';
import Card from '../../components/card/card';
import UploadInput from '../../components/upload-input/upload-input';
import classes from "./Terminal.module.css";
import Loader from '../../components/loader/loader';

function BulkTerminalList() {
	const [loading, setLoading] = useState(false);

	const handleUploadSubmission = () => {
		setLoading(true);
	}

	return (
		<div style={{ marginTop: 40 }}>
			<Card className="box-card">
				<div>
					<div className="uplodoc-iconed-label">
						<UploadInput
							id="cacDoc"
							labelName="upload file (xls, csv)"
							appearance="dotted-fade"
							type="file"
							size='lg'
							excel={true}
						// getFile={file => setFileName(file.name)}
						// getExcel={handleProcess}
						/>
					</div>

					<br />

					{true && <Alert
						message={'Terminal_ID.csv'}
						type="warning"
						closeText={<CloseOutlined />}
						// onClose={removeFile}
						style={{ marginBottom: 10 }}
					/>}
				</div>

				<small><a href="../../assets/excels/bulk_test.xlsx" download>Download Sample</a></small>

				<br /><br />

				<div className="submit-button">
				<div style={{ marginTop: 32 }}>
					<button onClick={handleUploadSubmission} type="submit" className={classes.button} disabled={loading}>
						{
							loading? <Loader /> : 'Upload Terminals'
						}
					</button>
				</div>
				</div>
			</Card>
		</div>
	);
}

export default BulkTerminalList;
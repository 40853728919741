import React from "react";
import PageHeader from "../components/PageHeader/PageHeader";
import SettingsNav from "../components/SettingNav/SettingNav";
import { getAdmin } from "../Redux/Api/admin";
import InviteAdmin from "./InviteAdmin";
import Permissions from "./Permissions";
import ViewAdmin from "./ViewAdmin";
import { useDispatch } from "react-redux";
const settingNavContent = [
  {
    state: "settings-active",
    name: "Invite Admins",
    path: "/invite-amdins",
  },
  {
    state: "settings-inactive",
    name: "View Admins",
    path: "/view-amdins",
  },
  {
    state: "settings-inactive",
    name: "Permission",
    path: "/permissions",
  },
];
const Admin = () => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    getAdmin(dispatch);
  }, [dispatch]);
  const [settingPage, setsettingPage] = React.useState(() => 0);
  return (
    <div className="settings-box-management">
      <PageHeader heading="Admin Page" />

      <SettingsNav
        navContent={settingNavContent}
        handleClick={(index) => {
          setsettingPage((prev) => index);
        }}
      />

      <div className="settings-pseudo-body">
        {settingPage === 0 && <InviteAdmin />}
        {settingPage === 1 && <ViewAdmin />}
        {settingPage === 2 && <Permissions />}
      </div>

      <br />
      <br />
    </div>
  );
};

export default Admin;

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageHeader from "../../components/PageHeader/PageHeader";
import SettingsNav from "../components/SettingNav/SettingNav";
import UserOverviewNav from "../components/UserNavs/overview";
import UserTransactions from "../components/UserNavs/transactions.jsx";
import UserDetails from "../components/UserNavs/user-details";
import { transType } from "../Redux/Api/transType";
import { useParams } from "react-router-dom";
import { getKycPersonal } from "../Redux/Api/kycPersonal";
import Loader from "../components/Loader/Loader";
import { getUserPersonal } from "../Redux/Api/userPersonal";
import PosTerminals from "../components/UserNavs/posTerminals";
import { addTerminalRoute } from "../Lib/routing";

const NavContent = [
  {
    state: "settings-active",
    name: "Wallets",
  },
  {
    state: "settings-inactive",
    name: "User Details",
  },
  {
    state: "settings-inactive",
    name: "Transactions",
  },
  {
    state: "terminal-inactive",
    name: "Terminal",
  },
];

function UserNavContent() {
  const { userid } = useParams();
  const dispatch = useDispatch();
  const roles = JSON.parse(localStorage.getItem("ROLES"));

  const viewTerminal = addTerminalRoute(roles);
  React.useEffect(() => {
    transType(dispatch);
  }, [dispatch]);

  React.useEffect(() => {
    getKycPersonal(dispatch, userid);
  }, [dispatch, userid]);

  React.useEffect(() => {
    getUserPersonal(dispatch, userid);
  }, [dispatch, userid]);

  const loading = useSelector((state) => state.userPersonal.loading);

  const userData = useSelector((state) => state.userPersonal.userData);

  const dataTransaction = useSelector((state) => state.transType.transType);
  const [settingPage, setsettingPage] = useState(() => 0);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="settings-box-management">
          <PageHeader
            heading={userData?.user_data?.name || "No Name"}
            to="/users"
          />
          <SettingsNav
            navContent={NavContent}
            handleClick={(index) => {
              setsettingPage((prev) => index);
            }}
          />

          <div className="settings-pseudo-body">
            {settingPage === 0 && (
              <UserOverviewNav
                data={{
                  wallet: userData?.wallet,
                  id: userid,
                }}
              />
            )}
            {settingPage === 1 && <UserDetails data={userData} />}
            {settingPage === 2 && <UserTransactions data={dataTransaction} />}
            {settingPage === 3 && viewTerminal && (
              <PosTerminals
                account={{
                  terminal: userData.registered_terminal,
                  accountId: userData.account_id,
                }}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default UserNavContent;

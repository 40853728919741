import React from "react";
import classes from "./ContentLayout.module.css";

const ContentLayout = ({ children }) => {
  return (
    <div className={classes.main}>
      <div className={classes.children}>{children}</div>
    </div>
  );
};

export default ContentLayout;

import Tiles from "../../components/tiles/tiles";
import Wallet from "../Wallet/Wallet";
import { viewWalletRoute } from "../../Lib/routing";

function UserOverviewNav({ data }: any) {
  const roles = JSON.parse(localStorage.getItem("ROLES"));
  const viewWallet = viewWalletRoute(roles);

  const numberFormat = Intl.NumberFormat("en-US");
  const tilesElements = [
    {
      title: "MAIN BALANCE",
      currency: `₦ ${numberFormat.format(
        data?.wallet?.main_balance.toFixed(2)
      )}`,
      boldMid: "",
      iconSelf: (
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.29668 17.6986C3.64473 18.0064 4.21334 17.9328 4.56612 17.5331L16.9272 3.55611C17.2803 3.15694 17.2845 2.58361 16.9361 2.27555L14.7033 0.300829C14.3553 -0.00694876 13.7867 0.0672179 13.4336 0.466385L1.07279 14.4436C0.71973 14.8433 0.715564 15.4161 1.0639 15.7239L3.29668 17.6986Z"
            fill="white"
          />
          <path
            d="M3.21303 6.31502C4.92619 6.31502 6.31497 4.92623 6.31497 3.21307C6.31497 1.49992 4.92619 0.11113 3.21303 0.11113C1.49987 0.11113 0.111084 1.49992 0.111084 3.21307C0.111084 4.92623 1.49987 6.31502 3.21303 6.31502Z"
            fill="white"
          />
          <path
            d="M14.7869 17.8889C16.5001 17.8889 17.8889 16.5001 17.8889 14.787C17.8889 13.0738 16.5001 11.685 14.7869 11.685C13.0738 11.685 11.685 13.0738 11.685 14.787C11.685 16.5001 13.0738 17.8889 14.7869 17.8889Z"
            fill="white"
          />
        </svg>
      ),
    },
    {
      title: "COMMISSION BALANCE",
      currency: `₦ ${numberFormat.format(
        data?.wallet?.commission_balance.toFixed(2)
      )}`,
      boldMid: "",
      bottomElement: <span />,
      iconSelf: (
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="6" y="10" width="6" height="8" fill="white" />
          <path
            d="M17.2968 0H0.7032C0.315 0 0 0.315 0 0.7032V17.2968C0 17.685 0.315 18 0.7032 18H17.2968C17.685 18 18 17.685 18 17.2968V0.7032C18 0.315 17.685 0 17.2968 0ZM11.7072 16.5936H6.2928V11.1798H11.7072V16.5936ZM16.5936 16.5936H13.1136V10.4766C13.1136 10.0884 12.7986 9.7734 12.4104 9.7734H5.5896C5.2014 9.7734 4.887 10.0884 4.887 10.4766V16.5936H1.4064V7.3356C1.8114 7.5666 2.2788 7.6992 2.7774 7.6992C3.6012 7.6992 4.3428 7.3386 4.8516 6.7668C5.3604 7.3386 6.102 7.6992 6.9258 7.6992C7.7496 7.6992 8.4912 7.3386 9 6.7668C9.5088 7.3386 10.2504 7.6992 11.0742 7.6992C11.898 7.6992 12.6396 7.3386 13.1484 6.7668C13.6572 7.3386 14.3988 7.6992 15.2226 7.6992C15.7206 7.6992 16.1886 7.5666 16.5936 7.3356V16.5936ZM16.5936 4.9218C16.5936 5.6778 15.9786 6.2928 15.2226 6.2928C14.4666 6.2928 13.8516 5.6778 13.8516 4.9218C13.8516 4.5336 13.5366 4.2186 13.1484 4.2186C12.7602 4.2186 12.4452 4.5336 12.4452 4.9218C12.4452 5.6778 11.8302 6.2928 11.0742 6.2928C10.3182 6.2928 9.7032 5.6778 9.7032 4.9218C9.7032 4.5336 9.3882 4.2186 9 4.2186C8.6118 4.2186 8.2968 4.5336 8.2968 4.9218C8.2968 5.6778 7.6818 6.2928 6.9258 6.2928C6.1698 6.2928 5.5548 5.6778 5.5548 4.9218C5.5548 4.5336 5.2398 4.2186 4.8516 4.2186C4.4634 4.2186 4.1484 4.5336 4.1484 4.9218C4.1484 5.6778 3.5334 6.2928 2.7774 6.2928C2.0214 6.2928 1.4064 5.6778 1.4064 4.9218V1.4058H16.5936V4.9218Z"
            fill="white"
          />
        </svg>
      ),
    },
    {
      title: "INCENTIVES BALANCE",
      currency: `₦ ${numberFormat.format(
        data?.wallet?.incentive_balance.toFixed(2)
      )}`,
      boldMid: "",
      iconSelf: (
        <svg
          width="18"
          height="16"
          viewBox="0 0 18 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.8039 10.9662C13.6618 11.6645 12.1343 12.0491 10.5031 12.0491C9.28346 12.0491 8.12193 11.8339 7.13608 11.4335C7.25581 11.4375 7.37603 11.4398 7.49683 11.4398C9.28439 11.4398 10.9713 11.0103 12.2467 10.2305C13.5882 9.41031 14.327 8.29732 14.327 7.09661V5.90494C14.4922 5.9867 14.6517 6.07377 14.8039 6.1668C15.8799 6.82471 16.4725 7.67692 16.4725 8.56649C16.4725 9.45607 15.8799 10.3083 14.8039 10.9662ZM3.19606 7.24285C2.12004 6.58494 1.52744 5.73273 1.52744 4.84316C1.52744 3.95351 2.12004 3.1013 3.19606 2.44346C4.33815 1.74517 5.86552 1.3606 7.49683 1.3606C9.12808 1.3606 10.6555 1.74517 11.7975 2.44346C12.8736 3.1013 13.4662 3.95351 13.4662 4.84316C13.4662 5.73273 12.8736 6.58494 11.7975 7.24285C10.6555 7.94107 9.12808 8.32565 7.49683 8.32565C5.86552 8.32565 4.33815 7.94107 3.19606 7.24285ZM17.3333 8.56649C17.3333 7.36571 16.5945 6.25272 15.253 5.4325C14.9645 5.25614 14.6539 5.09871 14.327 4.95906V4.84316C14.327 3.64238 13.5882 2.52939 12.2467 1.70917C10.9713 0.929399 9.28439 0.499924 7.49683 0.499924C5.70921 0.499924 4.0223 0.929399 2.74693 1.70917C1.40542 2.52939 0.666626 3.64238 0.666626 4.84316V7.09661C0.666626 8.29732 1.40542 9.41031 2.74693 10.2305C3.03544 10.4069 3.34598 10.5644 3.67295 10.704V10.8199C3.67295 12.0207 4.41174 13.1336 5.75325 13.9539C7.02869 14.7336 8.71553 15.1631 10.5031 15.1631C12.2906 15.1631 13.9776 14.7336 15.253 13.9539C16.5945 13.1336 17.3333 12.0207 17.3333 10.8199V8.61749H17.3323C17.3326 8.60049 17.3333 8.58356 17.3333 8.56649Z"
            fill="white"
          />
        </svg>
      ),
    },
    {
      title: "AIRPAY BALANCE",
      currency: `₦ ${numberFormat.format(
        data?.wallet?.airpay_balance.toFixed(2)
      )}`,
      boldMid: "",
      bottomElement: <span />,
      iconSelf: (
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="6" y="10" width="6" height="8" fill="white" />
          <path
            d="M17.2968 0H0.7032C0.315 0 0 0.315 0 0.7032V17.2968C0 17.685 0.315 18 0.7032 18H17.2968C17.685 18 18 17.685 18 17.2968V0.7032C18 0.315 17.685 0 17.2968 0ZM11.7072 16.5936H6.2928V11.1798H11.7072V16.5936ZM16.5936 16.5936H13.1136V10.4766C13.1136 10.0884 12.7986 9.7734 12.4104 9.7734H5.5896C5.2014 9.7734 4.887 10.0884 4.887 10.4766V16.5936H1.4064V7.3356C1.8114 7.5666 2.2788 7.6992 2.7774 7.6992C3.6012 7.6992 4.3428 7.3386 4.8516 6.7668C5.3604 7.3386 6.102 7.6992 6.9258 7.6992C7.7496 7.6992 8.4912 7.3386 9 6.7668C9.5088 7.3386 10.2504 7.6992 11.0742 7.6992C11.898 7.6992 12.6396 7.3386 13.1484 6.7668C13.6572 7.3386 14.3988 7.6992 15.2226 7.6992C15.7206 7.6992 16.1886 7.5666 16.5936 7.3356V16.5936ZM16.5936 4.9218C16.5936 5.6778 15.9786 6.2928 15.2226 6.2928C14.4666 6.2928 13.8516 5.6778 13.8516 4.9218C13.8516 4.5336 13.5366 4.2186 13.1484 4.2186C12.7602 4.2186 12.4452 4.5336 12.4452 4.9218C12.4452 5.6778 11.8302 6.2928 11.0742 6.2928C10.3182 6.2928 9.7032 5.6778 9.7032 4.9218C9.7032 4.5336 9.3882 4.2186 9 4.2186C8.6118 4.2186 8.2968 4.5336 8.2968 4.9218C8.2968 5.6778 7.6818 6.2928 6.9258 6.2928C6.1698 6.2928 5.5548 5.6778 5.5548 4.9218C5.5548 4.5336 5.2398 4.2186 4.8516 4.2186C4.4634 4.2186 4.1484 4.5336 4.1484 4.9218C4.1484 5.6778 3.5334 6.2928 2.7774 6.2928C2.0214 6.2928 1.4064 5.6778 1.4064 4.9218V1.4058H16.5936V4.9218Z"
            fill="white"
          />
        </svg>
      ),
    },
  ];

  return (
    <>
      <div className="tile-box-management" style={{ marginTop: 30 }}>
        {tilesElements.map((tile, index) => (
          <Tiles
            title={tile.title}
            currency={tile.currency}
            boldMid={tile.boldMid}
            iconSelf={tile.iconSelf}
            key={index}
          />
        ))}
      </div>
      {viewWallet && <Wallet id={data.id} />}
    </>
  );
}

export default UserOverviewNav;

import { createSlice } from "@reduxjs/toolkit";

export const kycSlice = createSlice({
  name: "kyc",
  initialState: {
    kycs: [],
    superAgents: [],
    loading: false,
    totalpage: 0,
    perpage: 0,
  },
  reducers: {
    startKyc: (state) => {
      state.loading = true;
    },
    fulfilledKyc: (state, { payload }) => {
      state.loading = false;
      state.kycs = payload.data;
      state.totalpage = payload.total;
      state.perpage = payload.perpage;
    },
    errorKyc: (state) => {
      state.loading = false;
    },
    fulfilledAgents: (state, { payload }) => {
      state.superAgents = payload;
    },
  },
});

export const { startKyc, fulfilledKyc, errorKyc, fulfilledAgents } =
  kycSlice.actions;

import React from "react";
import classes from "./Terminal.module.css";
import { useDispatch } from "react-redux";
import { Col, Row, Typography } from "antd";
//import { addService } from "../Redux/Api/service";


const { Title } = Typography;

const ViewSelectedTerminalInfo = ({ }) => {
    const dispatch = useDispatch();

    return (
        <>
            <Row className={classes.mt24}>
                <Col span={24}>
                    <h4 className={classes.title}>MERCHANT NAME</h4>
                    <p className={classes.description}>N/A</p>
                </Col>
            </Row>
            <Row className={classes.mt24}>
                <Col span={12}>
                    <h4 className={classes.title}>PHONE NUMBER</h4>
                    <p className={classes.description}>07012345678</p>
                </Col>
                <Col span={12}>
                    <h4 className={classes.title}>EMAIL ADDRESS</h4>
                    <p className={classes.description}>Keneohiaeri@gmail.com</p>
                </Col>
            </Row>
            <Row className={classes.mt24}>
                <Col span={12}>
                    <h4 className={classes.title}>ACCOUNT NUMBER</h4>
                    <p className={classes.description}>07012345678</p>
                </Col>
                <Col span={12}>
                    <h4 className={classes.title}>BANK</h4>
                    <p className={classes.description}>Keneohiaeri@gmail.com</p>
                </Col>
            </Row>
            <Row className={classes.mt24}>
                <Col span={12}>
                    <h4 className={classes.title}>TERMINAL ID</h4>
                    <p className={classes.description}>07012345678</p>
                </Col>
                <Col span={12}>
                    <h4 className={classes.title}>MERCHANT ID</h4>
                    <p className={classes.description}>Keneohiaeri@gmail.com</p>
                </Col>
            </Row>
            <Row className={classes.mt24}>
                <Col span={12}>
                    <h4 className={classes.title}>SLIPPER HEADER</h4>
                    <p className={classes.description}>07012345678</p>
                </Col>
                <Col span={12}>
                    <h4 className={classes.title}>FOOTER HEADER</h4>
                    <p className={classes.description}>Keneohiaeri@gmail.com</p>
                </Col>
            </Row>
        </>
    );
};

export default ViewSelectedTerminalInfo;
